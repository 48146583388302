import { Button } from '../slices/tableSlice';

export const dashboardButton: Button = {
    label: 'Leave Game',
    id: 'dashboard',
    value: true,
    highlighted: false,
    key: 'button-0'
};

export const nextHandButton: Button = {
    label: 'Next Board',
    id: 'nexthand',
    value: true,
    highlighted: false,
    key: 'button-4'
};

export const continueButton: Button = {
    label: 'Continue',
    id: 'nexthand',
    value: true,
    highlighted: false,
    key: 'button-4'
};

export const prevHandButton: Button = {
    label: 'Prev. Board',
    id: 'prevhand',
    value: true,
    highlighted: false,
    key: 'button-3'
};

export const forwardButton: Button = {
    label: 'Forward', //should be showing appropriate wind / re-wind glyphs
    id: 'forward',
    value: true,
    highlighted: false,
    key: 'button-4'
};

export const backwardButton: Button = {
    label: 'Backward', //should be showing appropriate wind / re-wind glyphs
    id: 'backward',
    value: true,
    highlighted: false,
    key: 'button-3'
};

export const startPlayButton: Button = {
    label: 'Continue',
    id: 'startplay',
    value: true,
    highlighted: false,
    key: 'button-5'
};

export const stopPlayButton: Button = {
    label: 'Pause',
    id: 'stopplay',
    value: true,
    highlighted: false,
    key: 'button-8'
};

export const replayButton: Button = {
    label: 'Replay',
    id: 'replay',
    value: true,
    highlighted: false,
    key: 'button-8'
};

export const undoButton: Button = {
    label: 'Undo',
    id: 'undo',
    value: true,
    highlighted: false,
    key: 'button-6'
};

export const claimButton: Button = {
    label: 'Claim',
    id: 'claim',
    value: true,
    highlighted: false,
    key: 'button-7'
};

export const settingsButton: Button = {
    label: 'Settings',
    id: 'show-shark-settings',
    value: true,
    highlighted: false,
    key: 'button-10'
};
