import { GameEngine } from '../game-engine-helper';
import { MID_ACTION_CONTROL } from './MID_ACTION_CONTROL';
import { MID_CHANGE_HAND_VISIBILITY } from './MID_CHANGE_HAND_VISIBILITY';
import { MID_CLAIM_NOTIFICATION } from './MID_CLAIM_NOTIFICATION';
import { MID_CLAIM_RESPONSE, MID_CLAIM_RESPONSE_SB } from './MID_CLAIM_RESPONSE';
import { MID_CONTRACT } from './MID_CONTRACT';
import { MID_CURRENT_DEAL, MID_CURRENT_DEAL_SB, MID_NEW_DEAL, MID_NEW_DEAL_SB } from './MID_CURRENT_DEAL';
import { MID_END_OF_SET } from './MID_END_OF_SET';
import { MID_MADE_BID, MID_MADE_BID_SB } from './MID_MADE_BID';
import { MID_MADE_PLAY } from './MID_MADE_PLAY';
import { MID_SET_HANDS } from './MID_SET_HANDS';
import { MID_SHOW_WELCOME } from './MID_SHOW_WELCOME';
import { MID_START_ROUND } from './MID_START_ROUND';
import { MID_TEXT_MESSAGE } from './MID_TEXT_MESSAGE';
import { MID_TIME_CLOSE_TRICK } from './MID_TIME_CLOSE_TRICK';
import { MID_TIME_TO_BID } from './MID_TIME_TO_BID';
import { MID_TIME_TO_PLAY } from './MID_TIME_TO_PLAY';
import { MID_TIME_TO_SCORE, MID_TIME_TO_SCORE_SB } from './MID_TIME_TO_SCORE';
import { MID_UNDO_NOTIFICATION } from './MID_UNDO_NOTIFICATION';
import { MID_UNDO_RESPONSE } from './MID_UNDO_RESPONSE';
import { MID_WAIT_FOR_ROUND } from './MID_WAIT_FOR_ROUND';
import { SFSObject } from 'sfs2x-api';

export default {
    MID_TIME_TO_BID,
    MID_MADE_BID,
    MID_MADE_BID_SB,
    MID_CONTRACT,
    MID_TIME_TO_PLAY,
    MID_MADE_PLAY,
    MID_TIME_TO_SCORE,
    MID_TIME_TO_SCORE_SB,
    MID_NEW_DEAL,
    MID_NEW_DEAL_SB,
    MID_CURRENT_DEAL,
    MID_CURRENT_DEAL_SB,
    MID_TIME_CLOSE_TRICK,
    MID_CHANGE_HAND_VISIBILITY,
    MID_CLAIM_NOTIFICATION,
    MID_CLAIM_RESPONSE,
    MID_CLAIM_RESPONSE_SB,
    MID_UNDO_RESPONSE,
    MID_ACTION_CONTROL,
    MID_END_OF_SET,
    MID_START_ROUND,
    MID_SET_HANDS,
    MID_UNDO_NOTIFICATION,
    MID_WAIT_FOR_ROUND,
    MID_TEXT_MESSAGE,
    MID_IGNORE,
    MID_SHOW_WELCOME,
};

export function MID_IGNORE(cmdObj: SFSObject, sender: GameEngine) {
    //
}
