import './walk-through-component.scss';
import { ButtonComponent } from '../button-component/button-component';
import { TableState } from '../../slices/tableSlice';
import { handleGlossaryAndQuizActions, handleGlossaryMouseOut } from '../../utils/mixed';
import { outputActions } from '../../slices/outputSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import AuctionBoxComponent from '../auction-box-component/auction-box-component';
import React from 'react';
import SharkBoardReviewComponent from '../shark-board-review-component/shark-board-review-component';
import SharkBoardStatsComponent from '../shark-board-stats-component/shark-board-stats-component';

export type WalkThroughComponentName = 'sharkBoardStatsComponent' | 'sharkBoardReviewComponent' | 'iFrame';

type WalkThroughComponentContents = Record<
    WalkThroughComponentName,
    typeof SharkBoardStatsComponent | typeof SharkBoardReviewComponent | typeof IFrame
>;

const IFrame: React.FC = () => {
    const { walkThroughIframe } = useAppSelector((state) => state.table);
    if (!walkThroughIframe) {
        return null;
    }
    return (
        <div className="walk-through-iframe">
            <iframe src={walkThroughIframe} />
        </div>
    );
};

export const walkThroughComponentContent: WalkThroughComponentContents = {
    sharkBoardStatsComponent: SharkBoardStatsComponent,
    sharkBoardReviewComponent: SharkBoardReviewComponent,
    iFrame: IFrame,
};

export const WalkThroughComponent: React.FC = () => {
    const { indexButtons, showIndexButtons, walkThrough, walkThroughShowComponents } = useAppSelector(
        (state) => state.table,
    );
    const dispatch = useAppDispatch();
    const { output_setWalkThruTarget } = outputActions;

    return (
        <section
            className="WalkThroughComponent"
            onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
            }}
            onTouchStart={(event) => {
                event.preventDefault();
                event.stopPropagation();
                dispatch(output_setWalkThruTarget(handleGlossaryAndQuizActions(event, true)));
            }}
            onMouseDown={(event) => {
                event.preventDefault();
                event.stopPropagation();
                dispatch(output_setWalkThruTarget(handleGlossaryAndQuizActions(event, true)));
            }}
            onMouseOver={(event) => {
                event.preventDefault();
                event.stopPropagation();
                dispatch(output_setWalkThruTarget(handleGlossaryAndQuizActions(event, false)));
            }}
            onTouchEnd={(event) => {
                event.preventDefault();
                event.stopPropagation();
                dispatch(output_setWalkThruTarget(handleGlossaryMouseOut(event)));
                //Close the tool tip
            }}
            onMouseOut={(event) => {
                event.preventDefault();
                event.stopPropagation();
                dispatch(output_setWalkThruTarget(handleGlossaryMouseOut(event)));
                //Close the tool tip
            }}
        >
            <div className="content" dangerouslySetInnerHTML={{ __html: walkThrough?.content ?? '' }} />
            <WalkThroughContentComponents components={walkThroughShowComponents} />
            {showIndexButtons && (
                <div className="index-buttons">
                    {indexButtons.map((button, index) => (
                        <ButtonComponent key={`walkThroughButtons-${index}`} button={button} />
                    ))}
                </div>
            )}
        </section>
    );
};

const WalkThroughContentComponents: React.FC<{
    components: TableState['walkThroughShowComponents'];
}> = ({ components }) => {
    if (!components.length) {
        return null;
    }
    return (
        <>
            {components.map((component) => {
                const Component = walkThroughComponentContent[component];
                return <Component key={component} />;
            })}
        </>
    );
};
