import { GameEngine } from '../game-engine-helper';
import { SFSObject } from 'sfs2x-api';
import { SFSVAR } from '../sfsVar';

export function MID_CHANGE_HAND_VISIBILITY(cmdObj: SFSObject, sender: GameEngine) {
    //North visible if handVisibility & 2 != 0, East & 4, South & 8, West &16
    if ((sender.state.playerID > 0 && cmdObj.getBool('os')) || (sender.state.playerID <= 0 && !cmdObj.getBool('os'))) {
        console.log('process_MID_CHANGE_HAND_VISIBILITY ', cmdObj);
        sender.setHandVisibility(cmdObj.getByte(SFSVAR.SFSGAME_MID_LOAD));
    }
}
