import { Bid, BridgePosition } from '../../app/types';
import { GameEngine, convertIntToBridgePosition, convertIntToCall } from '../game-engine-helper';
import { SFSObject } from 'sfs2x-api';
import { SFSVAR } from '../sfsVar';
import { bridgePos } from '../../app/defaults';
import { convertIntToBid } from '../shark-game-engine-helper';

export function MID_MADE_BID(cmdObj: SFSObject, sender: GameEngine) {
    // this.props.setActiveSeat(undefined);
    // this.props.setSoundPlaying(undefined);
    // this.props.setEnabledBiddingBox(false);
    //console.log('MID_MADE_BID', cmdObj.getDump());
    const call: number = cmdObj.getInt(SFSVAR.SFSGAME_MID_LOAD);
    const nextPlayerBridgePosition: number = cmdObj.getInt(SFSVAR.SFSGAME_CURENT_PLAYER);
    const player: BridgePosition = convertIntToBridgePosition(nextPlayerBridgePosition);
    //      this.props.setIsHighlighted(convertIntToBridgePosition(nextPlayerBridgePosition), true);

    if (
        sender.sfs?.lastJoinedRoom.containsVariable(SFSVAR.SFSGAME_MINIBRIDGE) &&
        sender.sfs?.lastJoinedRoom.getVariable(SFSVAR.SFSGAME_MINIBRIDGE).value
    ) {
        return;
    }

    const bridgePositionArray =
        sender.props.table.dealer === BridgePosition.north
            ? [BridgePosition.north, BridgePosition.east, BridgePosition.south, BridgePosition.west]
            : sender.props.table.dealer === BridgePosition.east
            ? [BridgePosition.east, BridgePosition.south, BridgePosition.west, BridgePosition.north]
            : sender.props.table.dealer === BridgePosition.south
            ? [BridgePosition.south, BridgePosition.west, BridgePosition.north, BridgePosition.east]
            : [BridgePosition.west, BridgePosition.north, BridgePosition.east, BridgePosition.south];

    const bid: Bid = {
        bridgePosition: bridgePositionArray[sender.props.table.auction.length % bridgePositionArray.length],
        call: convertIntToCall(call),
        backgroundColor: undefined,
    };

    if (cmdObj.containsKey(SFSVAR.SFSGAME_MID_ALERT)) {
        const { table } = sender.props;
        const playerBridgePosition: number = cmdObj.getInt(SFSVAR.SFSGAME_PLAYER);
        const mybridgeposition: BridgePosition | undefined = table.seatData.bottom.bridgePosition;
        const bidderParnerBridgePossition: BridgePosition = convertIntToBridgePosition(playerBridgePosition ^ 2);
        if (bidderParnerBridgePossition !== mybridgeposition) {
            bid.alertMessage = cmdObj.getUtfString(SFSVAR.SFSGAME_MID_ALERT);
            //this.props.addChatEntry({ timestamp: moment.utc().format(), sender: 'Alert!', message: bid.alertMessage });
        }
    }

    sender.props.table_setAuction([...sender.props.table.auction, bid]);

    sender.setCurrentPlayer(player);
}

export function MID_MADE_BID_SB(cmdObj: SFSObject, sender: GameEngine) {
    // console.log('MID_MADE_BID_SB', cmdObj.getDump());
    // this.props.setActiveSeat(undefined);
    // this.props.setSoundPlaying(undefined);
    // this.props.setEnabledBiddingBox(false);
    const call: number = cmdObj.getInt(SFSVAR.SFSGAME_MID_LOAD);
    const nextPlayerBridgePosition: number = cmdObj.getInt(SFSVAR.SFSGAME_CURENT_PLAYER);
    const player: BridgePosition = convertIntToBridgePosition(nextPlayerBridgePosition);
    //      this.props.setIsHighlighted(convertIntToBridgePosition(nextPlayerBridgePosition), true);

    if (
        sender.sfs?.lastJoinedRoom.containsVariable(SFSVAR.SFSGAME_MINIBRIDGE) &&
        sender.sfs?.lastJoinedRoom.getVariable(SFSVAR.SFSGAME_MINIBRIDGE).value
    ) {
        return;
    }

    const bridgePositionArray =
        sender.props.table.dealer === BridgePosition.north
            ? [BridgePosition.north, BridgePosition.east, BridgePosition.south, BridgePosition.west]
            : sender.props.table.dealer === BridgePosition.east
            ? [BridgePosition.east, BridgePosition.south, BridgePosition.west, BridgePosition.north]
            : sender.props.table.dealer === BridgePosition.south
            ? [BridgePosition.south, BridgePosition.west, BridgePosition.north, BridgePosition.east]
            : [BridgePosition.west, BridgePosition.north, BridgePosition.east, BridgePosition.south];

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const expl: object | undefined = cmdObj.containsKey('expl')
        ? JSON.parse(new TextDecoder().decode(cmdObj.getByteArray('expl')))
        : undefined;
    let alert: string | undefined = undefined;
    if (cmdObj.containsKey(SFSVAR.SFSGAME_MID_ALERT)) {
        const { table } = sender.props;
        const playerBridgePosition: number = cmdObj.getInt(SFSVAR.SFSGAME_PLAYER);
        const mybridgeposition: BridgePosition | undefined = table.seatData.bottom.bridgePosition;
        const bidderParnerBridgePossition: BridgePosition = bridgePos(playerBridgePosition ^ 2);
        if (bidderParnerBridgePossition !== mybridgeposition) {
            alert = cmdObj.getUtfString(SFSVAR.SFSGAME_MID_ALERT);
            sender.setSimpleMessage('right', `Alert!, ${alert}`, 'OK');
        }
    }
    const bid: Bid = {
        bridgePosition: bridgePositionArray[sender.props.table.auction.length % bridgePositionArray.length],
        call: convertIntToCall(call),
        backgroundColor: undefined,
        explanation: expl,
        alertMessage: alert,
    };

    sender.props.table_setAuction([...sender.props.table.auction, bid]);

    sender.setCurrentPlayer(player);

    sender.updateBoardData(undefined);
}
