import { BridgePosition, levels } from '../../app/types';
import { CallComponent } from '../call-component/call-component';
import { useAppSelector } from '../../app/hooks';
import { useTranslation } from 'react-i18next';
import React, { FunctionComponent } from 'react';

import './trick-counter-component.scss';

const TrickCounterComponent: FunctionComponent = () => {
    const { sharkLayout } = useAppSelector((state) => state.app);
    const { board, declarer, nstricks, ewtricks, contract } = useAppSelector((state) => state.table);

    const { t } = useTranslation();

    const getOfTranslation = (partner: 'nstricks' | 'ewtricks') => {
        if (!contract?.level) {
            return '';
        }
        const of = levels.indexOf(contract.level) + 6;
        if (partner === 'nstricks') {
            return declarer === BridgePosition.north || declarer === BridgePosition.south
                ? t('trickCount.of', { of })
                : '';
        } else if (partner === 'ewtricks') {
            return declarer === BridgePosition.east || declarer === BridgePosition.west
                ? t('trickCount.of', { of })
                : '';
        } else {
            return '';
        }
    };

    return (
        <section className="TrickCounterComponent">
            <div className="contractStake">
                <div className="board">
                    {!!board && [
                        ...(board.label
                            ? [
                                  <div key="boardLabel" className="boardLabel">
                                      {board.label}
                                  </div>,
                              ]
                            : []),
                        ...(board.value
                            ? [
                                  <div key="boardValue" className="boardValue">
                                      {board.value}
                                  </div>,
                              ]
                            : []),
                    ]}
                </div>
                <div>
                    {!!contract && (
                        <div className="contract">
                            <div className="declarer">{declarer && t(`bridgePosition.${declarer}`).substr(0, 1)}</div>
                            <CallComponent
                                key={`${contract.level}-${contract.strain}`}
                                call={contract}
                                selectedCardSet={sharkLayout.selectedCardSet}
                            />
                        </div>
                    )}
                    {!!contract && contract?.stake > 1 && (
                        <div className="stake">
                            <CallComponent
                                key={`stake-${contract.stake}`}
                                call={{
                                    call: contract.stake === 2 ? 'xdbl' : 'rdbl',
                                    level: contract.level,
                                    strain: contract.stake === 2 ? 'double' : 'redouble',
                                }}
                                selectedCardSet={sharkLayout.selectedCardSet}
                            />
                        </div>
                    )}
                </div>
            </div>
            <div className="trickCount">
                <span>{t(`trickCount.label.ns`)}</span>
                <span>
                    {nstricks}
                    {getOfTranslation('nstricks')}
                </span>
                <span>{t(`trickCount.label.ew`)}</span>
                <span>
                    {ewtricks}
                    {getOfTranslation('ewtricks')}
                </span>
            </div>
        </section>
    );
};

export default TrickCounterComponent;
