import './simple-tricks-counter-component.scss';
import { CardBackComponent } from '../card-component/card-component';
import { SeatPosFor } from '../../app-interfaces/game-engine-helper';
import { SeatPosition, Trick } from '../../app/types';
import { classNames } from '../../utils/mixed';
import { tableActions } from '../../slices/tableSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import React from 'react';

export const SimpleTricksCounterComponent: React.FC = () => {
    const {
        seatData: {
            bottom: { size },
        },
        tricks,
        nstricks,
        ewtricks,
    } = useAppSelector((state) => state.table);
    return (
        <section className={classNames('SimpleTricksCounterComponent', `size-${size}`)}>
            <div className="seat-hand">
                <div className="seat-hand-inner">
                    {tricks
                        .filter((trick) => Boolean(trick.winner))
                        .map((trick, index) => (
                            <ClaimedTrickComponent key={`trick-${index}`} trick={trick} index={index} />
                        ))}
                    {/*<HandComponent cards={[]} dummyCards={13} isInteractive={false} isVertical={false} isVisible={false} />*/}
                </div>
            </div>
            <div className="tray">
                <div>
                    <span>North-South</span>
                    <span>{nstricks}</span>
                </div>
                <div>
                    <span>East-West</span>
                    <span>{ewtricks}</span>
                </div>
            </div>
        </section>
    );
};

const ClaimedTrickComponent: React.FC<{ trick: Trick; index: number }> = ({ trick, index }) => {
    const dispatch = useAppDispatch();
    const { table } = useAppSelector((state) => state);
    const { seatData } = useAppSelector((state) => state.table);
    const seatPosition = SeatPosFor(trick.winner, table);
    const horizontal = seatPosition === SeatPosition.left || seatPosition === SeatPosition.right;
    return (
        <div
            className={classNames('card-container', horizontal && 'horizontal', trick.winner)}
            onClick={() => dispatch(tableActions.table_showClaimedTrick(index))}
        >
            <CardBackComponent />
        </div>
    );
};
