import { PossibleCurrentPlayerHighlight, PossibleInteraction } from '../features/shark-settings-component/options';
import { ReactElement } from 'react';
import { SFS_HAND_PROPS } from '../utils/mixed';
import { SoundFile } from '../_basics/audioPlayer';
import { TableState } from '../slices/tableSlice';

export enum BridgePosition {
    north = 'north',
    east = 'east',
    south = 'south',
    west = 'west',
}

export enum SeatPosition {
    top = 'top',
    right = 'right',
    bottom = 'bottom',
    left = 'left',
}

export enum Vulnerable {
    none = 'none',
    ns = 'ns',
    ew = 'ew',
    both = 'both',
}

export enum Suit {
    clubs = 'clubs',
    hearts = 'hearts',
    spades = 'spades',
    diamonds = 'diamonds',
}

export enum Rank {
    ace = 'ace',
    king = 'king',
    queen = 'queen',
    jack = 'jack',
    ten = 'ten',
    nine = 'nine',
    eight = 'eight',
    seven = 'seven',
    six = 'six',
    five = 'five',
    four = 'four',
    three = 'three',
    two = 'two',
}

export const suits: Suit[] = [Suit.spades, Suit.hearts, Suit.diamonds, Suit.clubs];
export const ranks: Rank[] = [
    Rank.ace,
    Rank.king,
    Rank.queen,
    Rank.jack,
    Rank.ten,
    Rank.nine,
    Rank.eight,
    Rank.seven,
    Rank.six,
    Rank.five,
    Rank.four,
    Rank.three,
    Rank.two,
];

export type Bid = {
    alertMessage?: string | undefined;
    call: Call;
    backgroundColor?: string | undefined;
    bridgePosition?: BridgePosition;
    disabled?: boolean;
    invisible?: boolean;
    key?: number;
    level?: Level;
    strain?: Strain;
    explanation?: object | undefined;
};

export type Auction = Bid[];

export type Call =
    | '1c'
    | '1d'
    | '1h'
    | '1nt'
    | '1s'
    | '2c'
    | '2d'
    | '2h'
    | '2nt'
    | '2s'
    | '3c'
    | '3d'
    | '3h'
    | '3nt'
    | '3s'
    | '4c'
    | '4d'
    | '4h'
    | '4nt'
    | '4s'
    | '5c'
    | '5d'
    | '5h'
    | '5nt'
    | '5s'
    | '6c'
    | '6d'
    | '6h'
    | '6nt'
    | '6s'
    | '7c'
    | '7d'
    | '7h'
    | '7nt'
    | '7s'
    | 'pass'
    | 'q'
    | 'rdbl'
    | 'xdbl';

export enum CardPosition {
    DECK = 'DECK',
    HAND = 'HAND',
    TRICK = 'TRICK',
    CLAIMED_TRICK = 'CLAIMED_TRICK',
}

export type CardId =
    | 'c2'
    | 'c3'
    | 'c4'
    | 'c5'
    | 'c6'
    | 'c7'
    | 'c8'
    | 'c9'
    | 'ca'
    | 'cj'
    | 'ck'
    | 'cq'
    | 'ct'
    | 'd2'
    | 'd3'
    | 'd4'
    | 'd5'
    | 'd6'
    | 'd7'
    | 'd8'
    | 'd9'
    | 'da'
    | 'dj'
    | 'dk'
    | 'dq'
    | 'dt'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'h7'
    | 'h8'
    | 'h9'
    | 'ha'
    | 'hj'
    | 'hk'
    | 'hq'
    | 'ht'
    | 's2'
    | 's3'
    | 's4'
    | 's5'
    | 's6'
    | 's7'
    | 's8'
    | 's9'
    | 'sa'
    | 'sj'
    | 'sk'
    | 'sq'
    | 'st';

export type Card = {
    bridgePosition: BridgePosition | undefined;
    cardPosition: CardPosition;
    id: CardId;
    highlighted: boolean;
    raised: boolean;
    rank: Rank;
    suit: Suit;
    suitSymbol: boolean | undefined;
    visible: boolean | undefined;
};

export type Cards = Record<Card['id'], Card>;

export type SeatData = {
    background: string | undefined;
    bridgePosition: BridgePosition | undefined;
    cardWidthMultiplier: number;
    dummyCards: number;
    dynamicCardColumnsWidth: boolean;
    isInteractive: boolean;
    isMe: boolean;
    isVertical: boolean;
    isVisible: boolean;
    labelPrimary: string | undefined;
    labelSecondary: string | undefined;
    labelTertiary: string | undefined;
    seatPosition: SeatPosition | undefined;
    sfsProp: SFS_HAND_PROPS;
    size: 's' | 'm' | 'l' | 'd';
};

export enum SuitOrder {
    CDSH = 'CDSH',
    CHSD = 'CHSD',
    DCHS = 'DCHS',
    DSHC = 'DSHC',
    HCDS = 'HCDS',
    HSDC = 'HSDC',
    SDCH = 'SDCH',
    SHCD = 'SHCD',
    SHDC = 'SHDC',
    ENGINE = 'ENGINE',
}

export const suitOrders: SuitOrder[] = [SuitOrder.CHSD, SuitOrder.DSHC, SuitOrder.HSDC, SuitOrder.SHDC, SuitOrder.SHDC];

export enum GamePhase {
    PRE = 'PRE',
    DEAL = 'DEAL',
    BID = 'BID',
    BID_PLAY = 'BID_PLAY',
    PLAY = 'PLAY',
    END = 'END',
}

export type HandLayout = 'straight' | 'fan' | 'columns' | 'paper-style';

export enum BonusIndicator {
    'j' = 'major game', // 3nt, 4h, 4s
    'm' = 'minor game', // 5c, 5d
    's' = 'slam', // 6xx
    'g' = 'GrandSlam', // 7xx
}

export type WalkThrough = {
    content: string;
};

export type BiddingLadder = {
    bids: Bid[];
    bonusIndicators: BonusIndicator[];
    firstVisibleCall: Call;
    isActive: boolean;
    isVisible: boolean;
    minimalValidCall: Call;
    showArrow: boolean;
    showBonusIndicator: boolean;
    stake: Pick<Bid, 'disabled' | 'backgroundColor' | 'invisible'> & { call: Extract<Call, 'rdbl' | 'xdbl'> };
    pass: Pick<Bid, 'disabled' | 'backgroundColor' | 'invisible'>;
};

export type Trick = {
    cardIds: CardId[];
    winner?: BridgePosition | undefined;
};

export enum Speed {
    verySlow = 'verySlow',
    slow = 'slow',
    medium = 'medium',
    fast = 'fast',
    veryFast = 'veryFast',
    instant = 'instant',
}

export type DOMRectObj = {
    x: number;
    y: number;
    width: number;
    height: number;
    top: number;
    right: number;
    bottom: number;
    left: number;
};

export type ClickEventData = {
    value: string | undefined;
    target: string | undefined;
    boundingClientRect: DOMRectObj;
};

export type GlossaryEntry = {
    name: string;
    title: string;
    description: string;
};

export type GeneralLayout = 'default' | 'v_impaired';

export type Level = 'zero' | 'one' | 'two' | 'three' | 'four' | 'five' | 'six' | 'seven';
export type Strain =
    | Suit
    | 'noTrump'
    | 'double'
    | 'redouble'
    | 'pass'
    | 'auctionAlert'
    | 'auctionStop'
    | 'undefined'
    | 'q';
export const levels: Level[] = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven'];
export const strains: Strain[] = [
    Suit.clubs,
    Suit.diamonds,
    Suit.hearts,
    Suit.spades,
    'noTrump',
    'pass',
    'double',
    'redouble',
];

type SubCardDeck = {
    suits: ReactElement;
    king: string;
    queen: string;
    jack: string;
};
export type CardDeck = {
    ranks: ReactElement;
    biddings: ReactElement;
    diamonds: SubCardDeck;
    hearts: SubCardDeck;
    spades: SubCardDeck;
    clubs: SubCardDeck;
};

export type Contract = {
    level: Level;
    strain: Strain;
    stake: 1 | 2 | 4;
    call: Call;
};

export type ModalButton = {
    label?: string | JSX.Element;
    className?: string;
    primary?: boolean;
    onClick?: (id: Modal['id']) => void;
    externalTo?: string;
    to?: string;
    target?: string;
};

export type Modal = {
    body?: JSX.Element[];
    buttons?: ModalButton[];
    cancelButtonLabel?: string | JSX.Element;
    className?: string;
    header?: string | JSX.Element;
    id?: string;
    isBlockUI?: boolean;
    noClickOutside?: boolean;
    noHeaderClose?: boolean;
    noCancel?: boolean;
    onCancel?: () => void ;
    showForeignBoardReviewData?: boolean;
    fullSize?: boolean;
};

export type ForeignBoardReviewPlayerData = Pick<TableState, 'auction'> & {
    tricks: Trick[];
    uuid: SharkGameResultsTravelerV2['uuid'];
    declarer: BridgePosition | undefined;
};

export type ForeignBoardReviewData = Pick<
    TableState,
    // for short data
    | 'seatData'
    | 'contract'
    | 'gamePhase'
    | 'declarer'
    | 'dealer'
    // for auction box
    | 'vulnerable'
    | 'showAuctionBox'

    // for board stats
    | 'boardStats'
    | 'myBoardStatKeys'
> & {
    cards: Cards;
    playerData: ForeignBoardReviewPlayerData[];
};

export type TableResult = {
    boardNumber?: string;
    contract?: string;
    declarer?: string;
    ewPoints?: number;
    lead?: Card['id'];
    nsPoints?: number;
    result?: string;
};

export type SharkGameResultsTraveler = {
    boardindex: number;
    boardnumber: number;
    contract: string;
    declarer: 'n' | 's' | 'e' | 'w';
    dir: 'ns' | 'ew';
    lead: string;
    runningIMP: number;
    runningMP: number;
    resultIMP: number;
    resultMP: number;
    score: number;
    tricksResult: string;
    UUID: string;
    vs: string;
    vsPairId: SharkGameResultsPair['pairId'];
    pair: string;
};

export type SharkGameResultsTravelerWithPair = SharkGameResultsTraveler & {
    pairId?: SharkGameResultsPair['pairId'];
    p1?: SharkGameResultsPair['p1'];
    p2?: SharkGameResultsPair['p2'];
};

// export type SharkGameResultsTravelerWithPair = SharkGameResultsTraveler &
//     Pick<SharkGameResultsPair, 'p1' | 'p2' | 'pairId'>;
//
export interface SharkGameResultsPair {
    pairId: string;
    p1: string;
    p2: string;
    rankMP: string;
    rankIMP: string;
    runningMP: number;
    runningIMP: number;
    traveler: SharkGameResultsTraveler[];
    dir: SharkGameResultsTraveler['dir'];
}

export interface SharkGameResultsTravelerV2 {
    bi: number;
    bn: number;
    c: number;
    x: number;
    d: number;
    dir: 'ns' | 'ew';
    l: number;
    ruIMP: number;
    ruMP: number;
    reIMP: number;
    reMP: number;
    s: number;
    r: string;
    uuid: string;
    name: string;
    me: boolean;
}

export interface SharkGameResultsPairV2 {
    uuid: string;
    name: string;
    play_name?: string;
    rMP: string;
    rIMP: string;
    ruMP: number;
    ruMPP: number;
    ruIMP: number;
    me: boolean;
    traveler: SharkGameResultsTravelerV2[];
    dir: 'ns' | 'ew' | undefined;
    masterPoints?: number;
    p1?: { pid: string, PK: string };
    p2?: { pid: string, PK: string };
}

export type SharkGameResults = {
    BoardsPerRound?: number;
    EventName?: string;
    Game: string;
    HostName?: string;
    Pairs: SharkGameResultsPair[];
    Movement: 'Mitchell' | 'Howell';
    Rounds?: number;
    Scoring: 'MP' | 'IMP';
    ShowRanks: boolean;
    TimeStamp: number;
    OriginalStartUnixTime: number;
    UUID: string;
    myPairId?: SharkGameResultsPair['pairId'];
};

export interface SharkGameResultsV2 extends Omit<SharkGameResults, 'Pairs' | 'myPairId'> {
    Pairs: SharkGameResultsPairV2[];
}

export type AudreySettings = {
    fourColorCards: boolean;
    requiredNext: boolean;
    sound: boolean;
    speedCardAnimations: Speed;
    speedCards: Speed;
    speedScreens: Speed;
};

export type SharkSettings = {
    animations: boolean;
    currentPlayerHighlight: PossibleCurrentPlayerHighlight[];
    interactionBid: PossibleInteraction;
    interactionCard: PossibleInteraction;
    selectedCurrentPlayerBackground: string;
    showMock: boolean;
    showTableSlotBorders: boolean;
    showVideo: boolean;
    silent8x8: boolean;
    sounds: {
        [key in SoundFile]: boolean;
    };
    tableBackgrounds: {
        default: string;
        blockedByTeacher: string;
    };
    visible: boolean;
};

export type SharkLayout = {
    cardBack: number;
    colorSets: {
        suits: number;
        background: number;
    };
    generalLayout: GeneralLayout;
    handLayout: HandLayout;
    selectedCardSet: number;
    suitOrder: SuitOrder;
    tableBackground: number;
};

export const suitLookup: Record<string, number> = {
    c: 0,
    d: 1,
    h: 2,
    s: 3,
    n: 4,
};

export type BoardStatKeys = {
    r: number;
    s: number;
    c: number;
    d: number;
    x: number;
    l: number;
};

export type BoardStats = {
    result: number;
    count: number;
    key: BoardStatKeys;
};
