import React, { FunctionComponent, useEffect, useRef } from 'react';

import { ReactComponent as Svg } from '../../assets/images/table.svg';

import './table-symbol-component.scss';
import { BridgePosition, SeatPosition, Vulnerable } from '../../app/types';
import { bridgePositionToSeatPosition, classNames } from '../../utils/mixed';
import { useAppSelector } from '../../app/hooks';

const TableSymbolComponent: FunctionComponent = () => {
    const { board, dealer, seatData, vulnerable } = useAppSelector((state) => state.table);

    const svgRef = useRef(null);

    const setSymbolLabels = () => {
        if (!svgRef) {
            return;
        }

        const dealerSeatPosition: SeatPosition | undefined = bridgePositionToSeatPosition(seatData, dealer);

        const positions = Object.values(SeatPosition);

        positions.forEach((value, index) => {
            const label = document.getElementById(`${value}-label`);
            if (label) {
                label.textContent = dealerSeatPosition === value ? 'D' : '';
            }
        });

        const centerLabel = document.getElementById('center-label');
        if (centerLabel) {
            centerLabel.textContent = `${board?.boardNumber || ''}`;
        }
    };

    useEffect(() => {
        if (svgRef) {
            setSymbolLabels();
        }
    }, [svgRef, seatData]);

    let v: (SeatPosition | undefined)[] = [];
    if (vulnerable === Vulnerable.both) {
        v = [SeatPosition.top, SeatPosition.bottom, SeatPosition.right, SeatPosition.left];
    } else if (vulnerable === Vulnerable.ew) {
        v = [
            bridgePositionToSeatPosition(seatData, BridgePosition.west),
            bridgePositionToSeatPosition(seatData, BridgePosition.east),
        ];
    } else if (vulnerable === Vulnerable.ns) {
        v = [
            bridgePositionToSeatPosition(seatData, BridgePosition.north),
            bridgePositionToSeatPosition(seatData, BridgePosition.south),
        ];
    }

    return (
        <section
            className={classNames(
                'TableSymbolComponent',
                `v-${v.join(' v-')}`,
                `${board?.boardNumber}`.length === 3 && 'digit-3',
                `${board?.boardNumber}`.length > 3 && 'digit-4',
            )}
        >
            <Svg ref={svgRef} />
        </section>
    );
};

export default TableSymbolComponent;
