import { AudreyGameEngineInterface } from './game-engine-interface.audrey.class';
import {
    BridgePosition,
    Call,
    CardId,
    SeatData,
    SeatPosition,
    Strain,
    Suit,
    SuitOrder,
    Vulnerable,
} from '../app/types';
import { GameEngineInterface } from './game-engine-interface.class';
import { RootState } from '../app/store';
import { SharkGameEngineInterface } from './game-engine-interface.shark.class';
import { TableState } from '../slices/tableSlice';
import { tableRotations } from '../utils/mixed';

export type GameEngine = GameEngineInterface | SharkGameEngineInterface | AudreyGameEngineInterface;
export const strToCardId = (sfsCode: string): CardId => {
    return sfsCode.toLowerCase() as CardId;
};

export const strToCall = (sfsCode: string): Call => {
    if (sfsCode === '?') {
        return 'q' as Call;
    }
    if (sfsCode === 'X' || sfsCode === 'x') {
        return 'xdbl' as Call;
    }
    if (sfsCode === 'XX' || sfsCode === 'xx') {
        return 'rdbl' as Call;
    }
    return sfsCode.toLowerCase() as Call;
};

export const convertIntToCall = (callId: number): Call => {
    //43
    if (callId === 0 || callId === 5) {
        return 'Pass' as Call;
    } else if (callId === 6) {
        return 'xdbl' as Call;
    } else if (callId === 7) {
        return 'rdbl' as Call;
    }
    const levelIndex = Math.floor(callId / 10); // 4
    const strainIndex = callId % 10; // 3
    return strToCall(`${levelIndex}${convertIntToSuitStr(strainIndex)}`);
};

export const convertCalltoStrain = (call: Call): Strain => {
    switch (call[1]) {
        case 'c':
            return Suit.clubs;
        case 'd':
            return Suit.diamonds;
        case 'h':
            return Suit.hearts;
        case 's':
            return Suit.spades;
        case 'n':
            return 'noTrump';
    }
    return 'undefined';
};

export const intToVuln = (vuln: number): Vulnerable => {
    switch (vuln) {
        case 0:
            return Vulnerable.none;
        case 1:
            return Vulnerable.ns;
        case 2:
            return Vulnerable.ew;
        case 3:
            return Vulnerable.both;
    }
    return Vulnerable.none;
};

export const strToSize = (sfsCode: string): 's' | 'm' | 'l' => {
    switch (sfsCode) {
        case 's':
            return 's';
        case 'n':
            return 'm';
        case 'l':
            return 'l';
    }
    return 'm';
};

export const sizeToCardMultiplier = (sfsCode: string, addextra: number): number => {
    switch (sfsCode) {
        case 's':
            return 0.8 + addextra;
        case 'n':
            return 1 + addextra;
        case 'l':
            return 1 + addextra;
    }
    return 1 + addextra;
};

export const fixColor = (sfsCode: string): string => {
    switch (sfsCode.toLowerCase()) {
        case 'y':
            return '#fce303';
        case 'g':
            return 'green';
        case 'r':
            return 'red';
    }
    return sfsCode;
};

export const intToSuitOrder = (sfsCode: number): SuitOrder => {
    switch (sfsCode) {
        case 0:
            return SuitOrder.SHCD;
        case 1:
            return SuitOrder.HSDC;
        case 2:
            return SuitOrder.DSHC;
        case 3:
            return SuitOrder.CHSD;
        case 4:
            return SuitOrder.SDCH;
        case 5:
            return SuitOrder.HCDS;
        case 6:
            return SuitOrder.DCHS;
        case 7:
            return SuitOrder.CDSH;
        case 8:
            return SuitOrder.ENGINE;
    }

    return SuitOrder.SHCD;
};

export const convertIntToBridgePosition = (pos: number): BridgePosition => {
    switch (pos) {
        case 0:
            return BridgePosition.north;
        case 1:
            return BridgePosition.east;
        case 2:
            return BridgePosition.south;
        case 3:
            return BridgePosition.west;
    }
    return BridgePosition.south;
};

export const playerIdToBridgePosition = (pos: number): BridgePosition => {
    switch (true) {
        case (pos & 1) !== 0:
            return BridgePosition.north;
        case (pos & 2) !== 0:
            return BridgePosition.east;
        case (pos & 4) !== 0:
            return BridgePosition.south;
        case (pos & 8) !== 0:
            return BridgePosition.west;
    }
    return BridgePosition.south;
};

export const swapPlayerIdToBridgePosition = (pos: number): BridgePosition => {
    switch (true) {
        case (pos & 1) !== 0:
            return BridgePosition.south;
        case (pos & 2) !== 0:
            return BridgePosition.west;
        case (pos & 4) !== 0:
            return BridgePosition.north;
        case (pos & 8) !== 0:
            return BridgePosition.east;
    }
    return BridgePosition.south;
};

export const convertPlayerIdToIntBridgePosition = (pos: number): number => {
    switch (true) {
        case (pos & 1) !== 0:
            return 0;
        case (pos & 2) !== 0:
            return 1;
        case (pos & 4) !== 0:
            return 2;
        case (pos & 8) !== 0:
            return 3;
    }
    return -1;
};

// export const SeatPosFor = (b: BridgePosition | undefined, statew: RootState): SeatPosition => {
//     const { seatData } = useSelector((state: RootState) => state.table);
//     return b ? tableRotations[seatData[SeatPosition.bottom].bridgePosition ?? BridgePosition.south][b] : SeatPosition.bottom;
// };

export const SeatPosFor = (b: BridgePosition | undefined, tableState: TableState): SeatPosition => {
    return b
        ? tableRotations[tableState.seatData[SeatPosition.bottom].bridgePosition ?? BridgePosition.south][b]
        : SeatPosition.bottom;
};

export const SeatPosForPlayerID = (playerID: number, tableState: TableState): SeatPosition => {
    return SeatPosFor(playerIdToBridgePosition(playerID), tableState);
};

export const getCardState = (hand: string, card: string, state: string): boolean => {
    let startWithSuit = 3;
    try {
        const chars = [...hand];
        let truState = false;
        chars.forEach((c, i) => {
            if (c === state) {
                if (card[0] === convertIntToSuitStr(startWithSuit) && card[1] === chars[i + 1]) {
                    truState = true;
                }
            } else if (c === '.') {
                startWithSuit--;
            } else if (c === ' ') {
                startWithSuit = 3;
            }
        });
        return truState;
    } catch {
        return false;
    }
};

export const convertIntToSuitStr = (suit: number): string => {
    switch (suit) {
        case 0:
            return 'C';
        case 1:
            return 'D';
        case 2:
            return 'H';
        case 3:
            return 'S';
        case 4:
            return 'NT';
    }
    return '';
};

export const stakeToStr = (suit: number): string => {
    switch (suit) {
        case 2:
            return 'X';
        case 4:
            return 'R';
    }
    return '';
};
