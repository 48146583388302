import { GameEngine } from '../game-engine-helper';
import { SFSObject } from 'sfs2x-api';
import { SFSVAR, getSFSTrue } from '../sfsVar';
import { TableInfo } from '../../slices/tableSlice';
import { environment } from '../../env';
import { getStringContract, getStringDeclarer } from '../sfsObjectConverter';

export function MID_CONTRACT(cmdObj: SFSObject, sender: GameEngine) {
    sender.props.table_updateBiddingLadder({
        isVisible: false,
    });
    update_contract(cmdObj, sender);
}

export function update_contract(cmdObj: SFSObject, sender: GameEngine) {
    const gameState: SFSObject | undefined = cmdObj.containsKey('tablestate')
        ? cmdObj.getSFSObject('tablestate')
        : undefined;
    const contract: SFSObject | undefined = gameState?.containsKey('contract')
        ? gameState.getSFSObject('contract')
        : cmdObj.containsKey('contract')
        ? cmdObj.getSFSObject('contract')
        : undefined;

    if (!contract) {
        return;
    }

    if (sender.state.isMiniBridge || getSFSTrue(gameState, SFSVAR.SFSGAME_NOAUCTION)) {
        sender.props.table_setShowAuctionLine(false);
    } else {
        sender.props.table_setShowAuctionLine(environment === 'audrey');
    }

    if (environment === 'audrey') {
        const passedHand = contract.getBool('passed');
        const completeAuction = contract.getInt('declarer') >= 0;

        if (contract.getBool('passed') || !completeAuction) {
            return;
        }

        sender.props.table_updateTable({
            showTrickCounter: environment,
        });

        const rightinfo: TableInfo[] = [];
        rightinfo.push({
            label: 'Contract: ',
            value: getStringContract(contract),
            hidden: false,
        });
        rightinfo.push({
            label: 'Declarer: ',
            value: getStringDeclarer(contract),
            hidden: false,
        });
        sender.props.table_setTableInfo({ left: sender.props.table.tableInfo.left, right: rightinfo });
    }
    sender.setContractFromSFSObject(contract);
}
