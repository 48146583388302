import { useTranslation } from 'react-i18next';
import React, { FunctionComponent } from 'react';

import './shark-trick-history-component.scss';
import { ForeignBoardReviewData, ForeignBoardReviewPlayerData, SeatPosition } from '../../app/types';
import { bridgePositionToSeatPosition, classNames, getMySeatData } from '../../utils/mixed';
import { getLeftOfBridgePosition, getPartnerBridgePosition } from '../../utils/shark-helper';

const seatPositions = [SeatPosition.top, SeatPosition.right, SeatPosition.bottom, SeatPosition.left];

export interface TrickHistoryProps {
    cards: ForeignBoardReviewData['cards'];
    declarer: ForeignBoardReviewData['declarer'];
    playerData: ForeignBoardReviewPlayerData;
    seatData: ForeignBoardReviewData['seatData'];
}

const SharkTrickHistoryComponent: FunctionComponent<TrickHistoryProps> = ({
    playerData,
    seatData,
    declarer,
    cards,
}) => {
    const { t } = useTranslation();

    const { tricks } = playerData;

    if (!declarer) {
        return null;
    }

    const firstLeader = getLeftOfBridgePosition(playerData.declarer ?? declarer, seatData);

    if (!firstLeader) {
        return null;
    }

    return (
        <section className="SharkTrickHistoryComponent">
            <div className="tricks">
                <div className="bridgePositions">
                    {seatPositions.map((seatPosition) => (
                        <div key={seatPosition} className={seatPosition}>
                            {seatData[seatPosition].bridgePosition?.substr(0, 1) ?? ''}
                        </div>
                    ))}
                </div>
                {tricks.map((trick, index) => {
                    const leader = index === 0 ? firstLeader : tricks[index - 1].winner;
                    const myBridgePosition = getMySeatData(seatData)?.bridgePosition;
                    const partnerBridgePosition = myBridgePosition
                        ? getPartnerBridgePosition(myBridgePosition)
                        : undefined;
                    const weWon = trick.winner === myBridgePosition || trick.winner === partnerBridgePosition;
                    const winnerSeatPosition = bridgePositionToSeatPosition(seatData, trick.winner);
                    return (
                        <div
                            key={`trick-${index}`}
                            className={classNames(`trick`, weWon && 'winner', `winner-${winnerSeatPosition}`)}
                        >
                            <div className="trick-index" />
                            {trick.cardIds.map((cardId) => {
                                const card = cards[cardId];
                                return (
                                    card.bridgePosition && (
                                        <div
                                            key={`trick-${card.suit}-${card.rank}-${index}`}
                                            className={classNames(
                                                'card',
                                                bridgePositionToSeatPosition(seatData, card.bridgePosition),
                                                card.suit,
                                                card.rank,
                                                leader === card.bridgePosition && 'leader'
                                            )}
                                        >
                                            <span>{t(`rank.short.${card.rank}`)}</span>
                                        </div>
                                    )
                                );
                            })}
                        </div>
                    );
                })}
            </div>
        </section>
    );
};

export default SharkTrickHistoryComponent;
