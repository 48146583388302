import './auxiliary-message-component.scss';
import { AuxiliaryMessage, Button, TableState } from '../../slices/tableSlice';
import { classNames, handleGlossaryAndQuizActions, handleGlossaryMouseOut } from '../../utils/mixed';
import { outputActions } from '../../slices/outputSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import React from 'react';

export const AuxiliaryMessageComponent: React.FC<{ position: keyof TableState['auxiliaryMessages'] }> = ({
    position,
}) => {
    const { auxiliaryMessages } = useAppSelector((state) => state.table);
    const dispatch = useAppDispatch();
    const message = auxiliaryMessages[position];
    const { output_setWalkThruTarget } = outputActions;

    const buttons: [
        AuxiliaryMessage['buttonAuxiliary'] | AuxiliaryMessage['buttonOk'] | AuxiliaryMessage['buttonCancel'],
        Button['id'],
    ][] = [
        [message?.buttonAuxiliary, 'auxiliaryAuxiliary'],
        [message?.buttonOk, 'auxiliaryOk'],
        [message?.buttonCancel, 'auxiliaryCancel'],
    ];

    //console.log("Message Buttons " , buttons);
    const hasButtons = message?.buttonCancel || message?.buttonOk || message?.buttonAuxiliary;

    return (
        <section className={classNames('AuxiliaryMessageComponent', position, hasButtons && 'hasButtons')}>
            {message?.title && (
                <div className="title" style={{ color: message.titleColor }}>
                    {message.title}
                </div>
            )}
            {message?.text && (
                <div
                    className="text"
                    dangerouslySetInnerHTML={{ __html: message.text }}
                    onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                    }}
                    onTouchStart={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        dispatch(output_setWalkThruTarget(handleGlossaryAndQuizActions(event, true)));
                    }}
                    onMouseDown={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        dispatch(output_setWalkThruTarget(handleGlossaryAndQuizActions(event, true)));
                    }}
                    onMouseOver={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        dispatch(output_setWalkThruTarget(handleGlossaryAndQuizActions(event, false)));
                    }}
                    onMouseOut={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        dispatch(output_setWalkThruTarget(handleGlossaryMouseOut(event)));
                        //Close the tool tip
                    }}
                />
            )}
            {hasButtons && (
                <div className="buttons">
                    {buttons.map(([label, id]) => {
                        return (
                            label &&
                            id && (
                                <button
                                    key={id}
                                    onClick={() =>
                                        dispatch(
                                            outputActions.output_setButton({
                                                id,
                                                value: true,
                                            }),
                                        )
                                    }
                                >
                                    {label}
                                </button>
                            )
                        );
                    })}
                </div>
            )}
        </section>
    );
};
