import { GameEngine } from '../game-engine-helper';
import { SFSObject } from 'sfs2x-api';

export function MID_SET_HANDS(cmdObj: SFSObject, sender: GameEngine) {
    sender.setHandsFromState(cmdObj, false);
    //North visible if handVisibility & 2 != 0, East & 4, South & 8, West &16
    // if (cmdObj.containsKey('northhand')) {
    //     this.setHandFromState(cmdObj.getUtfStringArray('northhand'), BridgePosition.north);
    // }
    // if (cmdObj.containsKey('easthand')) {
    //     this.setHandFromState(cmdObj.getUtfStringArray('easthand'), BridgePosition.east);
    // }
    // if (cmdObj.containsKey('southhand')) {
    //     this.setHandFromState(cmdObj.getUtfStringArray('southhand'), BridgePosition.south);
    // }
    // if (cmdObj.containsKey('westhand')) {
    //     this.setHandFromState(cmdObj.getUtfStringArray('westhand'), BridgePosition.west);
    // }
    // this.updateBoardData(undefined);
}
