import { BoardStatKeys, BoardStats, GamePhase } from '../../app/types';
import { GameEngine } from '../game-engine-helper';
import { SFSObject } from 'sfs2x-api';
import { SFSVAR } from '../sfsVar';
import {claimButton, continueButton, nextHandButton, undoButton} from '../buttonsState';
import {mockedAuxiliaryMessage} from '../../utils/mock-data';

export function MID_TIME_TO_SCORE(cmdObj: SFSObject, sender: GameEngine) {
    return;
}
export async function MID_TIME_TO_SCORE_SB(cmdObj: SFSObject, sender: GameEngine) {
    sender.hideButtons([claimButton, undoButton]);
    sender.props.table_setAuxiliaryMessage({ position: 'center', message: undefined });
    sender.props.table_setAuxiliaryMessage({ position: 'right', message: undefined });
    sender.props.table_setAuxiliaryMessage({ position: 'left', message: undefined });
    sender.props.table_setGamePhase(GamePhase.END);

    const oblState: SFSObject | undefined = cmdObj.containsKey('oplay') ? cmdObj.getSFSObject('oplay') : undefined;
    if (oblState) {
        sender.setHandsFromScore(oblState, true);
    }
    const nsTricks: number = cmdObj.containsKey('nstr')
        ? cmdObj.getInt('nstr')
        : oblState?.containsKey('nstr')
        ? oblState.getInt('nstr')
        : 0;
    const ewTricks: number = cmdObj.containsKey('ewtr')
        ? cmdObj.getInt('ewtr')
        : oblState?.containsKey('ewtr')
        ? oblState.getInt('ewtr')
        : 0;

    sender.props.table_setTrickCounts({ ns: nsTricks, ew: ewTricks });
    const score: number = cmdObj.getInt(SFSVAR.SFSGAME_MID_LOAD);

    if (!sender.state.isMiniBridge) {
        sender.props.table_setShowAuctionLine(true);
    }

    try {
        if (cmdObj.containsKey('stats')) {
            const json = new TextDecoder('utf-8').decode(cmdObj.getByteArray('stats'));
            const boardStats: BoardStats[] = JSON.parse(json) as BoardStats[];
            sender.props.table_setBoardStats(boardStats);
            if (cmdObj.containsKey('mystat')) {
                const myStatsKey: BoardStatKeys = JSON.parse(
                    new TextDecoder('utf-8').decode(cmdObj.getByteArray('mystat')),
                ) as BoardStatKeys;
                sender.props.table_setMyBoardStatKeys(myStatsKey);
            } else {
                sender.props.table_setMyBoardStatKeys({} as BoardStatKeys);
            }
        } else {
            sender.props.table_setBoardStats([]);
            sender.props.table_setMyBoardStatKeys({} as BoardStatKeys);
        }
    } catch (e) {
        //
    }
    const delay = () => new Promise((resolve) => setTimeout(resolve, 0));
    await delay();
    sender.updateBoardData(undefined);
    sender.props.table_setWalkTrough({ content: `Your Score: ${score}` });

    if (sender.state.hasNext) {
        sender.showButtons([nextHandButton]);
    }else {
        sender.showButtons([continueButton]);
    }

}
