// in this file no reducer files can be imported

import { Cards, ranks, suits } from '../app/types';
import { defaultCard } from '../app/defaults';
import { getCardId } from './mixed';

export const CardDeck = () => {
    return suits.reduce((cards, suit) => {
        return ranks.reduce((cards, rank) => {
            const id = getCardId(suit, rank);
            return {
                ...cards,
                [id]: {
                    ...defaultCard,
                    id,
                    rank,
                    suit,
                },
            };
        }, cards);
    }, {} as Cards);
};

export const enumToArray = (E: object) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const keys = Object.keys(E).filter((k) => typeof E[k] === 'number');
    return !keys.length ? Object.keys(E) : keys;
};
