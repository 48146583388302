import './shark-hand-component.scss';
import { Card, CardPosition, GamePhase, HandLayout, SeatData, Suit } from '../../app/types';
import { CardComponent, CardComponentProps, CardPlaceholderComponent } from '../card-component/card-component';
import { classNames } from '../../utils/mixed';
import { possibleCardBacks } from '../shark-settings-component/options';
import { useAppSelector } from '../../app/hooks';
import { useTranslation } from 'react-i18next';
import React, { FunctionComponent, useState } from 'react';

const dummyHand = [
    'dummyCard1',
    'dummyCard2',
    'dummyCard3',
    'dummyCard4',
    'dummyCard5',
    'dummyCard6',
    'dummyCard7',
    'dummyCard8',
    'dummyCard9',
    'dummyCard10',
    'dummyCard11',
    'dummyCard12',
    'dummyCard13',
];

export type SharkHandComponentProps = {
    currentSeatData: SeatData;
    hand: Card[];
    userVisible: boolean;
    forceHandLayout?: HandLayout;
};

const SharkHandComponent: FunctionComponent<SharkHandComponentProps> = (props) => {
    const { t } = useTranslation();
    const [selectedCard, setSelectedCard] = useState<Card | undefined>(undefined);

    const { currentSeatData, hand, forceHandLayout } = props;

    const { sharkLayout, sharkSettings } = useAppSelector((state) => state.app);
    const { selectedCardSet, handLayout, cardBack } = sharkLayout;

    const { gamePhase, myHighlightedCards } = useAppSelector((state) => state.table);

    const { dummyCards, isVisible, isInteractive } = currentSeatData;

    const handleClickCard = (card: Card) => () => {
        if (gamePhase === GamePhase.DEAL) {
            // CALL
            //props.dealCard(card.suit, card.rank, undefined);
        } else if (sharkSettings.interactionCard === 'confirm' && selectedCard !== card) {
            setSelectedCard(card);
        } else if (sharkSettings.interactionCard === 'instant' || selectedCard === card) {
            // CALL
            // props.makePlay(card);
        }
    };

    const handlePrivateHighlight = (cardId: Card['id']) => () => {
        // CALL
        // props.setMyHighlightedCards(cardId);
    };

    const getHandLayoutClass = (): HandLayout => {
        const { forceHandLayout } = props;
        if (forceHandLayout) {
            return forceHandLayout;
        }
        // WHAT IS THIS?
        // if (hasOverSize) {
        //     return 'straight';
        // }
        if (handLayout === 'paper-style' && !isVisible) {
            return 'straight';
        }
        return handLayout;
    };

    const renderHandWithCards = () => {
        return (
            <>
                {hand.map((card) => {
                    const { rank, suit } = card;
                    if (!rank || !suit) {
                        return null;
                    }
                    // ENABLE
                    // const cardProps: CardComponentProps = {
                    //     set: selectedCardSet,
                    //     selected: selectedCard && selectedCard.id === card.id,
                    //     ...(activeBridgePosition === currentSeatData.bridgePosition
                    //         ? { onClick: handleClickCard(card) }
                    //         : {}),
                    //     onPrivateHighlight: handlePrivateHighlight(card.id),
                    //     privateHighlighted: !!myHighlightedCards.find((_cardId) => _cardId === card.id),
                    //     raised,
                    // };
                    const cardProps: CardComponentProps = {
                        card,
                        hand: {
                            isVisible,
                            isInteractive,
                        },
                    };
                    return <CardComponent key={card.id} {...cardProps} />;
                })}
                {!!dummyCards &&
                    dummyHand.slice(0, dummyCards).map((dummy) => {
                        return <CardPlaceholderComponent key={dummy} />;
                    })}
            </>
        );
    };

    const renderStackedHand = () => {
        return [Suit.spades, Suit.hearts, Suit.diamonds, Suit.clubs].map((suit) => (
            <div key={suit} className={`suit ${suit}`}>
                {/*{[...hand, ...hand, ...hand, ...hand, ]*/}
                {hand
                    // .filter((card, index) => index < 13)
                    .filter((card) => card.suit === suit)
                    .map((card, index) => {
                        const { rank, suit } = card;
                        if (!rank || !suit) {
                            return null;
                        }
                        // return <div onClick={handleClickCard(card)}>{index + 1}</div>;
                        return (
                            <div
                                key={`${suit}-${card.id}`}
                                className={card.raised ? 'raised' : ''}
                                onClick={handleClickCard(card)}
                            >
                                {t(`rank.short.${rank}`)}
                            </div>
                        );
                    })}
            </div>
        ));
    };

    const renderHandLayout = () => {
        switch (true) {
            case forceHandLayout === 'paper-style':
                return renderStackedHand();
            case forceHandLayout === 'fan':
            case forceHandLayout === 'straight':
                return renderHandWithCards();
            case forceHandLayout === 'columns':
                return null; // todo: columns still not layouted
            case handLayout === 'fan':
            case handLayout === 'straight':
            case !isVisible:
                return renderHandWithCards();
            case handLayout === 'paper-style':
                return renderStackedHand();
            case handLayout === 'columns':
                return null; // todo: columns still not layouted
            default:
                return null;
        }
    };

    return (
        <section
            className={classNames(
                'SharkHandComponent',
                `set-${selectedCardSet}`,
                getHandLayoutClass(),
                `has-${hand.length}`,
            )}
            style={{ backgroundImage: `url(${possibleCardBacks[cardBack]})` }}
        >
            <div className="innerHand">{renderHandLayout()}</div>
        </section>
    );
};

// const mapDispatchToProps = {
//     makePlay,
//     dealCard,
//     setMyHighlightedCards,
// };

export default SharkHandComponent;
