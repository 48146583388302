import { BridgePosition, CardPosition, SeatData, SeatPosition } from '../app/types';
import { CardUpdateProps } from '../slices/cardsSlice';
import { SFSObject } from 'sfs2x-api';
import { SFS_HAND_PROPS, tableRotations, visibilityModifier } from '../utils/mixed';
import { SFS_HAND_STATE } from './sfsVar';
import { TableInfo } from '../slices/tableSlice';
import { fixColor, getCardState, sizeToCardMultiplier, strToCardId } from './game-engine-helper';

export const tableInfoFromSFSObject = (infoObj: SFSObject | undefined, obj: string): TableInfo => {
    if (!infoObj) {
        return undefined;
    }
    if (!infoObj.containsKey(obj + 'hidden') || infoObj.getBool(obj + 'hidden')) {
        return undefined;
    }
    return {
        label: infoObj.getUtfString(obj + 'label'),
        value: infoObj.getUtfString(obj + 'info'),
        hidden: infoObj.getBool(obj + 'hidden'),
    };
    return undefined;
};

export const cardSetFromString = (
    hand: string[],
    sfsHandState: SFS_HAND_STATE,
    stateStr: string,
): CardUpdateProps[] => {
    const cardUpdates: CardUpdateProps[] = hand.map((c) => ({
        bridgePosition: sfsHandState.bridgePosition,
        cardPosition: CardPosition.HAND,
        id: strToCardId(c),
        raised: getCardState(stateStr, c, '$'),
        highlighted: getCardState(stateStr, c, '!'),
        visible: getCardState(stateStr, c, '^') ? !sfsHandState.visible : undefined,
    }));
    return cardUpdates;
};

export const cardsToHands = (hand: string[], bridgePosition: BridgePosition | undefined): CardUpdateProps[] => {
    const cardUpdates: CardUpdateProps[] = hand.map((c) => ({
        cardPosition: CardPosition.HAND,
        id: strToCardId(c),
        bridgePosition: bridgePosition
    }));
    return cardUpdates;
};

export const seatDataFromSFSObject = (
    sfsHandState: SFS_HAND_STATE,
    sizeModifier: number,
    dynamicTray: boolean,
    hand: string[],
    isdummy: boolean,
    isvisible: boolean,
    handSize: string,
): { seatPosition: SeatPosition } & Partial<SeatData> => {
    //  console.log("Update rotated hand", sfsHandState);
    const isBottom: boolean = sfsHandState.seatPosition === 'bottom';
    const isVerticle: boolean = isdummy && !isBottom;
    const result: { seatPosition: SeatPosition } & Partial<SeatData> = {
        background: sfsHandState.sfsObject.containsKey('highlight')
            ? fixColor(sfsHandState.sfsObject.getUtfString('highlight'))
            : undefined,
        cardWidthMultiplier: sizeToCardMultiplier(sfsHandState.sfsObject.getUtfString('size'), sizeModifier),
        dynamicCardColumnsWidth: dynamicTray,
        dummyCards: hand.length > 0 && hand[0].length > 0 ? 0 : sfsHandState.sfsObject.getInt('ncards'),
        isVertical: isVerticle,
        isVisible: sfsHandState.visible || isvisible,
        labelPrimary: sfsHandState.sfsObject.getUtfString('firstl'),
        labelSecondary: sfsHandState.sfsObject.getUtfString('secondl'),
        labelTertiary: sfsHandState.sfsObject.getUtfString('thirdl'),
        seatPosition: sfsHandState.seatPosition ?? SeatPosition.top,
        size: isVerticle ? 'd' : !dynamicTray ? 'm' : (handSize as 's' | 'm' | 'l' | 'd'),
    };
    if (isdummy && !isVerticle) {
        result.labelPrimary += ' - Dummy';
    }
    return result;
};
export const getSFSHandState = (
    seatData: SeatData | undefined,
    state: SFSObject,
    visibility: number,
): SFS_HAND_STATE | undefined => {
    if (seatData) {
        if (state.containsKey(seatData.sfsProp)) {
            const bp: number = visibilityModifier[seatData.bridgePosition ?? BridgePosition.north] ?? 1;
            return {
                seatPosition: seatData.seatPosition ?? SeatPosition.top,
                bridgePosition: seatData.bridgePosition ?? BridgePosition.north,
                sfsObject: state.getSFSObject(seatData.sfsProp),
                visible: (visibility & bp) !== 0,
            };
        }
    } else if (state.containsKey(SFS_HAND_PROPS.center)) {
        //special case ( center hand
        return {
            seatPosition: SeatPosition.top,
            bridgePosition: BridgePosition.north,
            sfsObject: state.getSFSObject(SFS_HAND_PROPS.center),
            visible: true,
        };
    }
    return undefined;
};
