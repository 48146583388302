import { GameEngine } from '../game-engine-helper';
import { SFSObject } from 'sfs2x-api';

export function MID_ACTION_CONTROL(cmdObj: SFSObject, sender: GameEngine) {
    //North visible if handVisibility & 2 != 0, East & 4, South & 8, West &16
    // const stopAction: boolean = cmdObj.getBool(SFSVAR.SFSGAME_MID_LOAD);
    // const showNextDealButton: boolean = cmdObj.containsKey('snextb') ? cmdObj.getBool('snextb') : false;
    // sender.setState({ userActed: false });
    // if (showNextDealButton) {
    //     sender.props.showButtons(['startPlay']);
    // } else {
    //     sender.setStopActionControl(stopAction);
    //     sender.props.blockGameByTeacher(stopAction);
    // }
    //
    // if (cmdObj.containsKey('r_auction')) {
    //     //    console.log("Contract ", cmdObj.getDump(true));
    //     try {
    //         const auction: string[] = cmdObj.getUtfStringArray('r_auction');
    //         const calls: IBid[] = [];
    //         auction.forEach(call => {
    //             calls.push(convertStringToBid(call));
    //         });
    //         sender.updateBoardData({
    //             auction: calls,
    //             tricks: [],
    //             winner: [],
    //             declarer: BridgePosition.north
    //         });
    //     } catch (Exception) {}
    // }
    //
    // if (cmdObj.containsKey('message')) {
    //     const sfsObj = new SFSObject();
    //     sfsObj.putBool(SFSVAR.SFSGAME_MID_LOAD, false);
    //     sfsObj.putInt(SFSVAR.EXTENSION_MID, SFSVAR.netMID.MID_CONTROL_ACTION);
    //     const id = 'nextBoard';
    //
    //     this.showModal({
    //         id,
    //         noClickOutside: true,
    //         noHeaderClose: true,
    //         body: [<div dangerouslySetInnerHTML={{ __html: insertSuits(cmdObj.getUtfString('message')) }} />],
    //     cancelButtonLabel: <Translate contentKey="modal.ok" />,
    //         onCancel: () => store.dispatch(removeModal(id)),
    //         buttons: showNextDealButton
    //                  ? [
    //             {
    //                 label: <Translate contentKey="tray.nextBoard" />,
    //                 primary: true,
    //                 onClick: () => {
    //                     this.sendRoomCommand(SFSVAR.CMD_NEXT_DEAL, new SFSObject());
    //                     this.sendRoomCommand(SFSVAR.CMD_CONTROL_ACTION, sfsObj);
    //                     store.dispatch(removeModal(id));
    //                 }
    //             }
    //         ]
    //                  : [],
    //         className: id,
    //         showForeignBoardReviewData: true,
    //         fullSize: true
    // });
    // }
}
