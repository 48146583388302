import { GameEngine } from '../game-engine-helper';
import { SFSObject } from 'sfs2x-api';
import { SFSVAR } from '../sfsVar';

export function MID_WAIT_FOR_ROUND(cmdObj: SFSObject, sender: GameEngine) {
    // if (cmdObj.containsKey(SFSVAR.SFSGAME_ROOM_UTC_STRTIME)) {
    //     const currentTime: number = Math.floor(Date.now() / 1000);
    //     const endTime: number = this.state.myLobby.getVariable(SFSVAR.SFSGAME_ROOM_UTC_ENDTTIME).value; //SFSGAME_ROOM_UTC_STRTIME
    //     this.props.setTimeToGameEnd(endTime - currentTime);
    // }
    // if (cmdObj.containsKey(SFSVAR.SFSGAME_ROOM_STT)) {
    //     // console.log("Game Start time: ", cmdObj.getUtfString(SFSVAR.SFSGAME_ROOM_STT));
    //     this.props.setTimeToTournament(undefined);
    //     this.props.setTimeToTournament(Number(cmdObj.getUtfString(SFSVAR.SFSGAME_ROOM_STT)));
    // }
}
