import './root-component.scss';
import { BiddingLadderComponent } from '../bid-column-component/bidding-ladder-component';
import { GlossaryEntry } from '../../app/types';
import { SettingsComponent } from '../settings-component/settings-component';
import { TableComponent } from '../table-component/table-component';
import { TooltipComponent } from '../tooltip-component/tooltip-component';
import { WalkThroughComponent } from '../walk-through-component/walk-through-component';
import { appActions } from '../../slices/appSlice';
import { getLocalstorage, setLocalstorage } from '../../utils/mixed';
import { modalGameResults } from '../../utils/modal.helper';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import ModalComponent from '../modal-component/modal-component';
import React, { useEffect, useState } from 'react';

const glossaryDictionaryLifeTime = 12 * 60 * 60 * 1000;
const glossaryDictionaryLocalStorageKey = 'betterBridgeGlossaryDictionary';

export const RootComponent: React.FC = () => {
    const { walkThrough, biddingLadder, sharkGameResultsV2 } = useAppSelector((state) => state.table);
    const { urlParams, showSettings } = useAppSelector((state) => state.app);
    const { app_setGlossaryDictionary, app_addModal } = appActions;
    const dispatch = useAppDispatch();
    const [gameResultsFetched, setGameResultsFetched] = useState(false);

    useEffect(() => {
        let storedGlossaryDictionary: { dictionary: GlossaryEntry[]; timestamp: number } | undefined;
        storedGlossaryDictionary = getLocalstorage(glossaryDictionaryLocalStorageKey);
        if (
            storedGlossaryDictionary &&
            (!storedGlossaryDictionary.dictionary?.length ||
                !storedGlossaryDictionary.timestamp ||
                Date.now() - storedGlossaryDictionary.timestamp > glossaryDictionaryLifeTime)
        ) {
            storedGlossaryDictionary = undefined;
        }

        if (storedGlossaryDictionary) {
            dispatch(app_setGlossaryDictionary(storedGlossaryDictionary.dictionary));
        } else {
            fetchGlossaryDictionary().then((dictionary) => {
                if (dictionary) {
                    dispatch(app_setGlossaryDictionary(dictionary));
                    setLocalstorage(
                        glossaryDictionaryLocalStorageKey,
                        JSON.stringify({ dictionary, timestamp: Date.now() }),
                    );
                }
            });
        }
    }, []);

    useEffect(() => {
        if (urlParams?.gameResults && sharkGameResultsV2 && !gameResultsFetched) {
            const noCancel = urlParams?.dashboard === undefined;
            setGameResultsFetched(true);
            const _modalGameResults = modalGameResults({
                gameResults: sharkGameResultsV2,
                noCancel : noCancel,
                onCancel:() => {
                   if ( urlParams?.dashboard !== undefined ) {
                        parent.postMessage('reloaddash', '*');
                    }
                },
                noClickOutside: true,
                fullSize: true,
            });

            if (_modalGameResults) {
                dispatch(app_addModal(_modalGameResults));
            }
        }
    }, [sharkGameResultsV2, gameResultsFetched]);

    useEffect(() => {
        document.documentElement.classList.remove('with-walk-through-column');
        document.documentElement.classList.remove('with-bid-column');
        document.documentElement.classList.remove('with-walk-through-column-and-bid-column');
        if (walkThrough && biddingLadder.isVisible) {
            document.documentElement.classList.add('with-walk-through-column-and-bid-column');
        } else if (walkThrough) {
            document.documentElement.classList.add('with-walk-through-column');
        } else if (biddingLadder.isVisible) {
            document.documentElement.classList.add('with-bid-column');
        }
    }, [walkThrough, biddingLadder]);

    return (
        <section className="RootComponent">
            {!urlParams?.gameResults && (
                <>
                    <TableComponent />
                    {biddingLadder.isVisible && <BiddingLadderComponent />}
                    {walkThrough && <WalkThroughComponent />}
                    <TooltipComponent />
                    {showSettings && <SettingsComponent />}
                </>
            )}
            <ModalComponent />
        </section>
    );
};

function fetchGlossaryDictionary(): Promise<GlossaryEntry[] | void> {
    const url = 'https://dbcres.betterbridge.com/glossary.php';
    return fetch(url)
        .then((response) => {
            return response.json();
        })
        .then((responseJson) => {
            return responseJson as GlossaryEntry[];
        })
        .catch((error) => {
            console.error(`error fetching and resolving glossary data from ${url}`, error);
        });
}
