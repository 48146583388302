import './hand-component.scss';
import { Card, SeatData } from '../../app/types';
import { CardBackComponent, CardComponent, CardPlaceholderComponent } from '../card-component/card-component';
import { classNames, suitOrders } from '../../utils/mixed';
import { useAppSelector } from '../../app/hooks';
import React from 'react';

type HandComponentProps = {
    cards: Card[];
} & Pick<SeatData, 'dummyCards' | 'dynamicCardColumnsWidth' | 'isInteractive' | 'isVertical' | 'isVisible'>;

export const HandComponent: React.FC<HandComponentProps> = (props) => {
    const { cards, dummyCards, dynamicCardColumnsWidth, isVertical } = props;
    const cssCards: React.CSSProperties = isVertical
        ? {}
        : ({ '--cards': (cards ?? []).length + dummyCards } as React.CSSProperties);
    return (
        <section
            className={classNames(
                'HandComponent',
                isVertical && 'vertical',
                dynamicCardColumnsWidth && 'dynamic-card-columns-width',
            )}
            style={cssCards}
        >
            {cards?.length || dummyCards > 0 ? (
                isVertical ? (
                    <VerticalLayout {...props} />
                ) : (
                    <HorizontalLayout {...props} />
                )
            ) : (
                <div>
                    <CardPlaceholderComponent />
                </div>
            )}
        </section>
    );
};

export const HorizontalLayout: React.FC<HandComponentProps> = ({ cards, isInteractive, isVisible, dummyCards }) => {
    return (
        <>
            {cards.map((card, index) => (
                <div key={card.id} className={`normalCard-${index}`}>
                    <CardComponent card={card} hand={{ isVisible, isInteractive }} />
                </div>
            ))}
            {Array.from(Array(dummyCards).keys()).map((index) => (
                <div key={`dummyCard-${index}`} className={`dummyCard-${index}`}>
                    <CardBackComponent />
                </div>
            ))}
        </>
    );
};

export const VerticalLayout: React.FC<HandComponentProps> = ({ cards, isInteractive, isVisible }) => {
    const { suitOrder } = useAppSelector((state) => state.table);

    return (
        <>
            {suitOrders[suitOrder].map((suit) => {
                return (
                    <div key={suit} className={`suit ${suit}`}>
                        <div className={`suit-inner`}>
                            {cards
                                .filter((card) => card.suit === suit)
                                .map((card, cardIndex) => (
                                    <div key={card.id} className={`normalCard-${cardIndex}`}>
                                        <CardComponent card={card} hand={{ isVisible, isInteractive }} />
                                    </div>
                                ))}
                        </div>
                    </div>
                );
            })}
        </>
    );
};
