import { useTranslation } from 'react-i18next';
import React, { FunctionComponent } from 'react';

type TranslateProps = {
    contentKey: string;
    interpolate?: Record<string, string>;
};

export const Translate: FunctionComponent<TranslateProps> = (props) => {
    const { t } = useTranslation();
    return <>{t(props.contentKey, props.interpolate || {})}</>;
};
