import { SFSObject } from 'sfs2x-api';
import { convertIntToSuitStr, stakeToStr } from './game-engine-helper';

export const getStringContract = (contract: SFSObject): string => {
    return `${contract.getInt('level')}${convertIntToSuitStr(contract.getInt('trump')).toLowerCase()}${stakeToStr(
        contract.getInt('stake'),
    )}`;
};

export const getStringDeclarer = (contract: SFSObject): string => {
    const declarer: number = contract.getInt('declarer');

    return declarer === 0 ? 'North' : declarer === 1 ? 'East' : declarer === 2 ? 'South' : declarer === 3 ? 'West' : '';
};

/*
            * 		(int) stake: 1
		(int) leader: 1
		(int) trump: 2
		(int) declarer: 0
		(int) level: 3
		(bool) passed: false
            * */
