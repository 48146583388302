import { GameEngine } from '../game-engine-helper';
import { SFSObject } from 'sfs2x-api';

export function MID_CLAIM_RESPONSE(cmdObj: SFSObject, sender: GameEngine) {
    //North visible if handVisibility & 2 != 0, East & 4, South & 8, West &16
    if (cmdObj.containsKey('message')) {
        sender.setMessage('center', cmdObj.getSFSObject('message'));
    }
}

export function MID_CLAIM_RESPONSE_SB(cmdObj: SFSObject, sender: GameEngine) {
    console.log("MID_CLAIM_RESPONSE_SB", cmdObj);
    // this.props.removeCurrentModal();
}
