import { AppState } from '../../slices/appSlice';
import { Bid } from '../../app/types';
import { Sets } from '../card-component/sets';
import { useTranslation } from 'react-i18next';
import React, { FunctionComponent } from 'react';

import './call-component.scss';

export type BiddingBoxProps = {
    call: Bid;
    selectedCardSet: AppState['sharkLayout']['selectedCardSet'];
};

export const CallComponent: FunctionComponent<BiddingBoxProps> = (props) => {
    const { t } = useTranslation();
    const { call, selectedCardSet } = props;
    const { level, strain, alertMessage } = call;

    return (
        <section className={`Call ${level} ${strain} ${alertMessage ? 'withAlert' : ''}`}>
            {strain ? (
                ['double', 'redouble', 'pass', 'auctionAlert', 'auctionStop'].indexOf(strain) !== -1 ? (
                    <div>{t(`strain.${strain}`)}</div>
                ) : (
                    Sets[selectedCardSet].biddings
                )
            ) : (
                <div />
            )}
        </section>
    );
};
