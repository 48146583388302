import { Link } from 'react-router-dom';
import { ModalButton } from '../../app/types';
import { appActions } from '../../slices/appSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useTranslation } from 'react-i18next';
import React, { FunctionComponent } from 'react';
import ShortTableComponent from '../short-table-component/short-table-component';

import './modal-component.scss';
import { classNames } from '../../utils/mixed';

const ModalComponent: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { foreignBoardReviewData, seatData, contract, gamePhase } = useAppSelector((state) => state.table);
    const cards = useAppSelector((state) => state.cards);
    const { modals } = useAppSelector((state) => state.app);
    const { app_removeModal } = appActions;
    const modal = modals[0];

    if (!modal) {
        return null;
    }

    const {
        body,
        buttons,
        cancelButtonLabel,
        className,
        header,
        id,
        isBlockUI,
        noClickOutside,
        noHeaderClose,
        noCancel,
        onCancel,
        showForeignBoardReviewData,
        fullSize,
    } = modal;

    const handleCancel = () => {
        if (isBlockUI) {
            return;
        }
        dispatch(app_removeModal(id));
        !!onCancel && onCancel();
    };

    const handleClickOutside = () => {
        if (!noClickOutside) {
            handleCancel();
        }
    };

    const handleClickHeaderClose = () => {
        if (!noHeaderClose) {
            handleCancel();
        }
    };

    const handleButtonClick = (onClick: ModalButton['onClick']) => () => {
        if (isBlockUI) {
            return;
        }
        if (onClick) {
            onClick(id);
        }
    };

    return (
        <section className={classNames('ModalComponent', id, className, fullSize && 'fullSize')}>
            <div className="backdrop" onClick={handleClickOutside} />
            <div className="modal">
                {header && (
                    <header className={`${!isBlockUI ? 'hasCancel' : ''}`}>
                        {header}
                        {!isBlockUI && !noHeaderClose && (
                            <button className="cancel" onClick={handleCancel}>
                                {t('modal.close')}
                            </button>
                        )}
                    </header>
                )}
                <div className="body">
                    {showForeignBoardReviewData && foreignBoardReviewData ? (
                        <div className="auction-board-review">
                            <ShortTableComponent
                                seatData={seatData}
                                contract={contract}
                                gamePhase={gamePhase}
                                cards={cards}
                                scale={1.2}
                            />
                            <div className="auctions">
                                {foreignBoardReviewData.playerData.map((playerData, index) => (
                                    <div key={playerData.uuid}>
                                        <h3>{index === 0 ? 'Your Auction' : 'Recommended'}</h3>
                                        {/*<AuctionBox*/}
                                        {/*    foreignBoardData={{*/}
                                        {/*        auction: playerData.auction,*/}
                                        {/*        seatData: foreignBoardReviewData.seatData,*/}
                                        {/*        showAuctionBox: foreignBoardReviewData.showAuctionBox,*/}
                                        {/*        vulnerability: foreignBoardReviewData.vulnerability,*/}
                                        {/*    }}*/}
                                        {/*/>*/}
                                    </div>
                                ))}
                                <div className="text">
                                    {body?.map((bodyPart, index) => (
                                        <React.Fragment key={`bodyPart-${index}`}>{bodyPart}</React.Fragment>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            {body?.map((bodyPart, index) => (
                                <React.Fragment key={`bodyPart-${index}`}>{bodyPart}</React.Fragment>
                            ))}
                        </>
                    )}
                </div>
                {!isBlockUI && !(!buttons && noCancel) && (
                    <footer className={!buttons?.length ? 'singleButton' : ''}>
                        {!noCancel && (
                            <button type="button" className="cancel" onClick={handleCancel}>
                                {cancelButtonLabel ? cancelButtonLabel : t('modal.cancel')}
                            </button>
                        )}
                        {(buttons || []).map((button, index) => {
                            const {
                                className: buttonClassName,
                                label,
                                onClick,
                                primary,
                                to,
                                externalTo,
                                target,
                            } = button;
                            const buttonProps = {
                                label,
                                onClick: handleButtonClick(onClick),
                                className: `${buttonClassName ? buttonClassName : ''} ${primary ? 'primary' : ''}`,
                            };
                            if (externalTo) {
                                return (
                                    <a
                                        key={`${id}-link-${index}`}
                                        href={externalTo}
                                        {...buttonProps}
                                        {...(target ? { target } : {})}
                                    >
                                        {button.label}
                                    </a>
                                );
                            } else if (to) {
                                return (
                                    <Link key={`${id}-link-${index}`} to={to} {...buttonProps}>
                                        {button.label}
                                    </Link>
                                );
                            } else {
                                return (
                                    <button key={`${id}-button-${index}`} type="button" {...buttonProps}>
                                        {button.label}
                                    </button>
                                );
                            }
                        })}
                    </footer>
                )}
            </div>
        </section>
    );
};

export default ModalComponent;
