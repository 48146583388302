import { GameEngine } from '../game-engine-helper';
import { SFSObject } from 'sfs2x-api';

export function MID_START_ROUND(cmdObj: SFSObject, sender: GameEngine) {
    // sender.props.setSoundPlaying('tournamentStart');
    // if (sender.state.lobbyRoom.containsVariable(SFSVAR.SFSGAME_REM_TIME)) {
    //     sender.props.setTimeToTournament(undefined);
    //     sender.props.setTimeToGameEnd(sender.state.lobbyRoom.getVariable(SFSVAR.SFSGAME_REM_TIME).value);
    // } else if (sender.sfs?.lastJoinedRoom.containsVariable(SFSVAR.SFSGAME_REM_TIME)) {
    //     sender.props.setTimeToTournament(undefined);
    //     sender.props.setTimeToGameEnd(sender.sfs?.lastJoinedRoom.getVariable(SFSVAR.SFSGAME_REM_TIME).value);
    // }
}
