import { useTranslation } from 'react-i18next';
import React from 'react';

import './shark-board-stats-component.scss';
import { BoardStatKeys, Suit } from '../../app/types';
import { suitArray } from '../../app-interfaces/shark-game-engine-helper';
import { useAppSelector } from '../../app/hooks';

export const suits = {
    C: 'clubs',
    S: 'spades',
    H: 'hearts',
    D: 'diamonds',
};
export const contractSuits: Record<string, string> = {
    '0': Suit.clubs,
    '1': Suit.diamonds,
    '2': Suit.hearts,
    '3': Suit.spades,
    '4': 'NT',
};

const SharkBoardStatsComponent: React.FC = () => {
    const game = useAppSelector((state) => state.table);
    const { t } = useTranslation();

    const d = [
        t('boardStats.bridgePosition.n'),
        t('boardStats.bridgePosition.e'),
        t('boardStats.bridgePosition.s'),
        t('boardStats.bridgePosition.w'),
    ];

    const columnTitles = [
        t('boardStats.declarer'),
        t('boardStats.contract'),
        t('boardStats.lead'),
        t('boardStats.tricks'),
        t('boardStats.score'),
        t('boardStats.result'),
        t('boardStats.players'),
    ];

    if (!game.foreignBoardReviewData) {
        return null;
    }

    const { boardStats, myBoardStatKeys } = game.foreignBoardReviewData;

    return (
        <section className={['SharkBoardStatsComponent', boardStats.length === 0 ? 'NoStats' : ''].join(' ')}>
            <div className="table">
                <div>
                    {columnTitles.map((title) => (
                        <div key={title}>{title}</div>
                    ))}
                </div>
                {boardStats.map((_boardStats, index) => {
                    const keyStats = (Object.keys(_boardStats.key) as (keyof BoardStatKeys)[])
                        .map((key) => _boardStats.key[key])
                        .sort()
                        .join('-');
                    const myStats = myBoardStatKeys
                        ? (Object.keys(myBoardStatKeys) as (keyof BoardStatKeys)[])
                              .map((key) => myBoardStatKeys[key])
                              .sort()
                              .join('-')
                        : '';
                    return (
                        <div key={`_boardStats-${index}`} className={keyStats === myStats ? 'isMe' : ''}>
                            <div className="declarer">{_boardStats.key.d >= 0 ? d[_boardStats.key.d] : '-'}</div>
                            <div className="contract">
                                <div
                                    className={
                                        _boardStats.key.c >= 10
                                            ? contractSuits[`${_boardStats.key.c}`.substring(1, 2)]
                                            : 'passOut'
                                    }
                                >
                                    {`${_boardStats.key.c}`.substring(1, 2) === '4' && (
                                        <div className="notrump">
                                            {contractSuits[`${_boardStats.key.c}`.substring(1, 2)]}
                                        </div>
                                    )}
                                    <div className="level">
                                        {_boardStats.key.c > 0 ? `${_boardStats.key.c}`.substring(0, 1) : 'Pass out'}
                                    </div>
                                    <div className="x">&nbsp;{' XX'.substring(0, _boardStats.key.x)}</div>
                                </div>
                            </div>
                            <div className={`lead`}>
                                <div
                                    className={
                                        _boardStats.key.l >= 0
                                            ? suits[suitArray[_boardStats.key.l].substring(0, 1) as keyof typeof suits]
                                            : ''
                                    }
                                >
                                    <span>
                                        {_boardStats.key.l >= 0 ? suitArray[_boardStats.key.l].substring(1) : '-'}
                                    </span>
                                </div>
                            </div>
                            <div className="tricks">
                                {_boardStats.key.c === 0
                                    ? '-'
                                    : _boardStats.key.r === 0
                                    ? '='
                                    : _boardStats.key.r > 0
                                    ? `+${_boardStats.key.r}`
                                    : _boardStats.key.r}
                            </div>
                            <div className="score">{_boardStats.key.s}</div>
                            <div className="result">
                                {Math.round((_boardStats.result + Number.EPSILON) * 100) / 100}
                            </div>
                            <div className="players">{_boardStats.count}</div>
                        </div>
                    );
                })}
            </div>
        </section>
    );
};

export default SharkBoardStatsComponent;
