import './table-info-component.scss';
import { Call } from '../../app/types';
import { TableInfoObject, TableState } from '../../slices/tableSlice';
import { calls } from '../../app/defaults';
import { classNames } from '../../utils/mixed';
import { useAppSelector } from '../../app/hooks';
import React from 'react';

export const TableInfoComponent: React.FC<{ side: keyof TableState['tableInfo'] }> = ({ side }) => {
    const {
        tableInfo: { [side]: infos },
    } = useAppSelector((state) => state.table);
    if (!infos || infos.length === 0) {
        return null;
    }
    return (
        <section className={classNames('TableInfoComponent', side)}>
            {infos.map((info, index) => {
                if (!info) {
                    return null;
                }
                if (typeof info === 'string') {
                    return (
                        <div key={`TableInfoLine-${index}`} className="text">
                            {info}
                        </div>
                    );
                }
                return (
                    <React.Fragment key={`TableInfoLine-${index}`}>
                        <div className="label">
                            <span>{info.label}</span>
                        </div>
                        <div className="value">{getValue(info.value)}</div>
                    </React.Fragment>
                );
            })}
        </section>
    );
};

const getValue = (value: TableInfoObject['value']) => {
    //value.replace("s", "")
    if (calls.includes(value.substring(0, 2) as Call)) {
        const level = value.charAt(0);
        const suit =
            value.charAt(1) === 's'
                ? '&spades;'
                : value.charAt(1) === 'c'
                ? '&clubs;'
                : value.charAt(1) === 'h'
                ? '&hearts;'
                : value.charAt(1) === 'd'
                ? '&diams;'
                : 'NT';
        const color: React.CSSProperties = ['h', 'd'].includes(value.charAt(1)) ? { color: 'red' } : { color: 'black' };
        const left = value.substring(2).toUpperCase();
        return (
            <div className="contract">
                <div className="level">{level}</div>
                <div className="suit" style={{ ...color }} dangerouslySetInnerHTML={{ __html: suit }} />
                <div className="level">{left}</div>
            </div>
        );
    }
    if (calls.includes(value.substring(0, 3) as Call)) {
        return (
            <div className={'contract'}>
                <div className="level">{value.toUpperCase()}</div>
            </div>
        );
    }
    return value;
};
