import './tooltip-component.scss';
import { GlossaryEntry } from '../../app/types';
import { useAppSelector } from '../../app/hooks';
import React from 'react';

export const TooltipComponent: React.FC = () => {
    const { glossaryDictionary } = useAppSelector((state) => state.app);
    const { tooltip } = useAppSelector((state) => state.table);

    if (glossaryDictionary && tooltip) {
        const glossaryEntry: GlossaryEntry | undefined = glossaryDictionary.find(
            (dictionaryEntry) => dictionaryEntry.name === tooltip.name,
        );
        if (!glossaryEntry) {
            return null;
        }

        const { top, left } = tooltip.boundingClientRect ?? {};

        return (
            <section
                className="TooltipComponent"
                // style={
                //     top && left
                //         ? {
                //               top,
                //               left,
                //               transform: 'translateX(-100%) translateY(-100%)',
                //           }
                //         : {}
                // }
            >
                <div className="title">{glossaryEntry.title}</div>
                <div className="text" dangerouslySetInnerHTML={{ __html: glossaryEntry.description }} />
            </section>
        );
    }
    return null;
};
