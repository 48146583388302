import { BridgePosition, CardPosition } from '../../app/types';
import { CardUpdateProps } from '../../slices/cardsSlice';
import {
    GameEngine,
    convertIntToBridgePosition,
    intToSuitOrder,
    intToVuln,
    playerIdToBridgePosition,
    strToCardId,
    swapPlayerIdToBridgePosition,
} from '../game-engine-helper';
import { SFSArray, SFSObject } from 'sfs2x-api';
import { SFSVAR } from '../sfsVar';
import { claimButton, replayButton, startPlayButton, stopPlayButton, undoButton } from '../buttonsState';
import { emptyTrick } from '../../app/defaults';

export function MID_NEW_DEAL(cmdObj: SFSObject, sender: GameEngine) {
    MID_CURRENT_DEAL(cmdObj, sender);
    sender.sendRoomCommand(SFSVAR.CMD_TABLE_READY, new SFSObject());
}
export function MID_CURRENT_DEAL(cmdObj: SFSObject, sender: GameEngine) {
    sender.props.table_reset();
    sender.props.table_setTopBridgePosition(BridgePosition.north);
    common(cmdObj, sender);
}

export function MID_NEW_DEAL_SB(cmdObj: SFSObject, sender: GameEngine) {
    MID_CURRENT_DEAL_SB(cmdObj, sender);
    sender.sendRoomCommand(SFSVAR.CMD_TABLE_READY, new SFSObject());
}

export async function MID_CURRENT_DEAL_SB(cmdObj: SFSObject, sender: GameEngine) {
    sender.props.table_reset();
    sender.props.table_setTopBridgePosition(swapPlayerIdToBridgePosition(sender.state.playerID));
    const delay = () => new Promise((resolve) => setTimeout(resolve, 0));
    await delay();
    sender.hideButtons([undoButton, claimButton, startPlayButton, stopPlayButton, replayButton]);
    common(cmdObj, sender);
    sender.updateBoardData(undefined);
}

function common(cmdObj: SFSObject, sender: GameEngine) {
    //console.log('---------------------------process_MID_CURRENT_DEAL');
    //console.log('Lobby Variables: \n ' + sender.state.myLobby);
    //console.log('The deal is: \n ' + pbnStr);
    //console.log('The deal is: \n ' + cmdObj.getDump());
    sender.props.app_removeModal('awaitApproval');
    sender.setState({
        currentPlayer: undefined,
    });
    //clear table data.

    sender.props.table_setForceShowAllCards(sender.forceShowAllCards);
    sender.props.table_setIndexButtons(sender.indexButtons);

    const gameState: SFSObject = cmdObj.getSFSObject('tablestate');
    const showdummy = gameState.containsKey('showdummy') && gameState.getBool('showdummy');
    const showBiddingbox = gameState.containsKey('biddingbox');

    sender.props.table_updateTable({
        vulnerable: intToVuln(gameState.getInt('vuln')),
    });

    if (cmdObj.containsKey('mainmessage')) {
        //console.log("Main Message : " , cmdObj.getUtfString('mainmessage'));
        //.replace(new RegExp("quiz:", 'g'), "quiz://")
        sender.props.table_setWalkTrough({ content: cmdObj.getUtfString('mainmessage') });
        if (showdummy) {
            sender.props.table_setCardWidthMultiplier(1.35);
        } else {
            sender.props.table_setCardWidthMultiplier(1.35);
        }
    } else {
        sender.props.table_setWalkTrough(undefined);
        if (!showBiddingbox) {
            sender.props.table_setCardWidthMultiplier(1.02);
        } else {
            sender.props.table_setCardWidthMultiplier(1.095);
        }
    }

    //Establish the menu buttons for this screen
    sender.setControlButtons(cmdObj.containsKey('buttons') ? cmdObj.getSFSObject('buttons') : undefined);

    if (gameState.containsKey('tablehtml')) {
        //console.log("Table HTML : " , gameState.getUtfString('tablehtml'));
        sender.props.table_setBridgeTableHTML(gameState.getUtfString('tablehtml'));
        return;
    }

    sender.props.table_setBridgeTableHTML(undefined);
    sender.props.table_setBridgeTableVisibility(true);

    const tableState: number = gameState.getInt('state');

    //reset all cards back to DECK.
    sender.props.cards_reset();

    sender.props.table_setPrimaryLabelToBridgePosition();

    //set the suit order
    //console.log("Highlight : ", oblState.getUtfStringArray("highlights"));
    //console.log("Highlighted String : ", oblState.getUtfString("highlightStr"));
    sender.props.table_setSuitOrder(intToSuitOrder(gameState.getInt('suitorder')));

    const contract: SFSObject = gameState.getSFSObject('contract'); //declarer
    const declarer: number = contract.getInt('declarer');
    const passed: boolean = contract.getBool('passed');

    const dealer: BridgePosition = convertIntToBridgePosition(gameState.getInt('dealer'));

    sender.props.table_setDealer(dealer);

    if (declarer !== -1 && !passed) {
        //We have contract set declarer
        sender.props.table_setDeclarer(convertIntToBridgePosition(declarer));
    }

    sender.setTableInfo(cmdObj);


    /*
    if (showdummy) {
        console.log("Dummy is ", convertIntToBridgePosition(declarer ^ 2));
       sender.props.table_setDummy(convertIntToBridgePosition(declarer ^ 2));
    }
    */
    //update hand trays and card holdings
    sender.setHandsFromState(gameState, cmdObj.containsKey('mainmessage'));

    //update auction
    sender.setAuctionFromState(gameState, dealer);

    //update bidding ladder
    sender.setBiddingLadderFromState(gameState);

    sender.setContractFromSFSObject(gameState);

    //Deal with the line of play
    const playArray: SFSArray | undefined = gameState.containsKey('bbplay')
        ? gameState.getSFSArray('bbplay')
        : gameState.containsKey('play')
        ? gameState.getSFSArray('play')
        : undefined;
    if (playArray !== undefined && playArray.size() > 0) {
        //console.log("Play state : " , playArray);
        setTrickHistory(playArray, sender);
    } else {
        // sender.setCardsToTrickFromHand(gameState);
    }

    sender.props.table_setTrickCounts({ ns: gameState.getInt('nstr'), ew: gameState.getInt('ewtr') });

    sender.props.table_setShowTrickCounter(gameState.getBool('showtc') ? 'audrey' : undefined);

    if (gameState.containsKey('centermessage')) {
        sender.setMessage('center', gameState.getSFSObject('centermessage'));
    }

    if (gameState.containsKey('bleftmessage')) {
        sender.setMessage('left', gameState.getSFSObject('bleftmessage'));
    }

    if (gameState.containsKey('brightmessage')) {
        sender.setMessage('right', gameState.getSFSObject('brightmessage'));
    }
}

function setTrickHistory(tricks: SFSArray, sender: GameEngine) {
    //console.log("Card array : " , tricks.getDump());
    const cardUpdates: CardUpdateProps[] = [];
    let winner: BridgePosition | undefined = undefined;
    for (let i = 0; i < tricks.size(); i++) {
        if (i === 0) {
            sender.props.table_addTrick(emptyTrick);
        }
        const card: SFSObject = tricks.getSFSObject(i);
        // console.log("Card to load : " , card.getDump());
        cardUpdates.push({
            bridgePosition: convertIntToBridgePosition(card.getInt('p')),
            cardPosition: CardPosition.TRICK,
            id: strToCardId(card.getUtfString('c')),
            raised: false,
            highlighted: false,
            visible: true,
        });
        sender.props.table_playCard(strToCardId(card.getUtfString('c')));
        if (card.getBool('w')) {
            winner = convertIntToBridgePosition(card.getInt('p'));
        }
        if (winner !== undefined && i > 0 && (i + 1) % 4 === 0) {
            //we are at the end of trick , close it
            //console.log("Closing trick : " , (i+1) % 4 , " : " , winner);
            sender.props.table_claimTrick(winner);
            sender.props.table_addTrick(emptyTrick);
            winner = undefined;
        }
    }
    sender.props.cards_setCards(cardUpdates);
}
