import { BridgePosition, CardId, CardPosition, SeatPosition } from '../../app/types';
import { CardUpdateProps } from '../../slices/cardsSlice';
import {
    GameEngine,
    SeatPosFor,
    convertIntToBridgePosition,
    strToCardId,
    swapPlayerIdToBridgePosition,
} from '../game-engine-helper';
import { SFSObject } from 'sfs2x-api';
import { SFSVAR } from '../sfsVar';
import { bridgePos, emptyTrick } from '../../app/defaults';

export function MID_MADE_PLAY(cmdObj: SFSObject, sender: GameEngine) {
 //   console.log('Made a play ', cmdObj.getDump());
    const cardid: CardId = strToCardId(cmdObj.getUtfString('o'));
    const cp: BridgePosition = convertIntToBridgePosition(cmdObj.getInt('cp'));
    if (cmdObj.getBool('showdummy')) {
        sender.props.table_setDummy(cp);
        //Should update dummy
    }
    sender.setHandsFromState(cmdObj, false);

    const player: BridgePosition = bridgePos(cmdObj.getInt('p'));
    const playerSeat: SeatPosition = SeatPosFor(player, sender.props.table);
    const nVisibleCards = Object.values(sender.props.cards).filter(
        (obj) => obj.bridgePosition === player && obj.cardPosition === CardPosition.HAND,
    ).length;
    let nPlayerCards: number = cmdObj.getInt('ncardsinplayer') - nVisibleCards;
    if (nPlayerCards < 0) {
        nPlayerCards = 0;
    }
    if (
        !sender.props.table.seatData[SeatPosFor(player, sender.props.table)].isVisible &&
        cmdObj.containsKey('ncardsinplayer')
    ) {
        sender.props.table_setSeatData({
            seatPosition: playerSeat,
            dummyCards: nPlayerCards,
        });
    }

    if (cmdObj.containsKey('message')) {
        const messenger: string = cmdObj.containsKey('messenger') ? cmdObj.getUtfString('messenger') : '';
        sender.setSimpleMessage('right', messenger, 'OK');
    }

    if (sender.props.table.tricks.length === 0) {
        sender.props.table_addTrick(emptyTrick);
    } else if (sender.props.table.tricks[sender.props.table.tricks.length - 1].cardIds.length === 4) {
        sender.props.table_claimTrick(cp);
        sender.props.table_addTrick(emptyTrick);
    }
    const cardUpdates: CardUpdateProps[] = [
        {
            bridgePosition: convertIntToBridgePosition(cmdObj.getInt('p')),
            cardPosition: CardPosition.TRICK,
            id: cardid,
            raised: false,
            highlighted: false,
            visible: true,
        },
    ];
    sender.props.cards_setCards(cardUpdates);
    sender.props.table_playCard(cardid);
    sender.props.table_setTrickCounts({ ns: cmdObj.getInt('nstr'), ew: cmdObj.getInt('ewtr') });
    if (cmdObj.containsKey('buttons')) {
        sender.setControlButtons(cmdObj.getSFSObject('buttons'));
    }

    if (cmdObj.getInt(SFSVAR.SFSGAME_GAME_STATE) === 18) {
        sender.props.table_shouldCloseTrick(true);
    }

    sender.setCurrentPlayer(cp);
}
