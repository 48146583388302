import {
    BridgePosition,
    Card,
    CardPosition,
    Cards,
    Contract,
    Modal,
    Rank,
    SeatData,
    SeatPosition,
    Suit,
    SuitOrder,
    TableResult,
    Vulnerable,
    levels,
    strains,
} from '../app/types';
import { TableState, initialState as initialTableState } from '../slices/tableSlice';
import { Translate } from '../_basics/translate';
import { appActions } from '../slices/appSlice';
import { bridgePositionToSeatPosition } from './mixed';
import { enumToArray } from './shark-initialHelper';
import { outputActions } from '../slices/outputSlice';
import { possibleSuitColorSets } from '../features/shark-settings-component/options';
import { store } from '../app/store';
import GameResults from '../features/shark-game-results-component/shark-game-results-component';
import GameResultsV2 from '../features/shark-game-results-component/shark-game-results-component-v2';
import React, { CSSProperties, ReactElement } from 'react';
import moment from 'moment';

const { output_setApproveClaim, output_setApproveUndo } = outputActions;
const { app_removeModal, app_clearModals, app_addModal } = appActions;

type ValidSuitOrders =
    | SuitOrder.SHCD
    | SuitOrder.SHDC
    | SuitOrder.HSDC
    | SuitOrder.DSHC
    | SuitOrder.CHSD
    | SuitOrder.ENGINE;

const suitOrders: Record<ValidSuitOrders, Suit[] | undefined> = {
    [SuitOrder.SHCD]: [Suit.spades, Suit.hearts, Suit.clubs, Suit.diamonds],
    [SuitOrder.SHDC]: [Suit.spades, Suit.hearts, Suit.diamonds, Suit.clubs],
    [SuitOrder.HSDC]: [Suit.hearts, Suit.spades, Suit.diamonds, Suit.clubs],
    [SuitOrder.DSHC]: [Suit.diamonds, Suit.spades, Suit.hearts, Suit.clubs],
    [SuitOrder.CHSD]: [Suit.clubs, Suit.hearts, Suit.spades, Suit.diamonds],
    [SuitOrder.ENGINE]: undefined,
};

const ranks = enumToArray(Rank);

const sortCards =
    (suitOrder?: Suit[]) =>
    (cardA: Card, cardB: Card): number => {
        const useSuitOrder = suitOrder || suitOrders[SuitOrder.SHCD] || [];
        const indexA = useSuitOrder.indexOf(cardA.suit);
        const indexB = useSuitOrder.indexOf(cardB.suit);
        if (indexA < indexB) {
            return -1;
        } else if (indexA > indexB) {
            return 1;
        } else {
            return ranks.indexOf(cardA.rank) - ranks.indexOf(cardB.rank);
        }
    };

export const getCardsOfSeat = (
    cards: Cards,
    bridgePosition: BridgePosition,
    cardPosition: CardPosition,
    suitOrder: ValidSuitOrders,
    trump?: Contract,
): Card[] => {
    let _suitOrder: Suit[] | undefined;
    if (suitOrder === SuitOrder.ENGINE) {
        if (!trump || trump.strain === Suit.spades) {
            _suitOrder = suitOrders[SuitOrder.SHCD];
        } else if (trump.strain === Suit.hearts) {
            _suitOrder = suitOrders[SuitOrder.HSDC];
        } else if (trump.strain === Suit.diamonds) {
            _suitOrder = suitOrders[SuitOrder.DSHC];
        } else if (trump.strain === Suit.clubs) {
            _suitOrder = suitOrders[SuitOrder.CHSD];
        }
    } else {
        _suitOrder = suitOrders[suitOrder];
    }

    return (Object.keys(cards) as Card['id'][])
        .filter((key) => cards[key].bridgePosition === bridgePosition && cards[key].cardPosition === cardPosition)
        .map((key) => cards[key])
        .sort(sortCards(_suitOrder));
};

// export const getRotatedSeatData = (
//     myBridgePosition: BridgePosition,
//     seatsBridgePosition: BridgePosition,
// ): Partial<SeatData> => {
//     return {
//         isMe: myBridgePosition === seatsBridgePosition,
//         seatPosition: tableRotations[myBridgePosition][seatsBridgePosition],
//     };
// };

export const getCallValue = (call: Contract) => {
    return strains.indexOf(call.strain) + 10 * levels.indexOf(call.level);
};

export const getHighestOfCalls = (calls: Contract[]) => {
    return [...calls].reduce((previousCall, currentCall) => {
        if (getCallValue(currentCall) > getCallValue(previousCall)) {
            return currentCall;
        } else {
            return previousCall;
        }
    }, calls[0]);
};

export const getPartnerBridgePosition = (bridgePosition: BridgePosition): BridgePosition => {
    return {
        [BridgePosition.north]: BridgePosition.south,
        [BridgePosition.east]: BridgePosition.west,
        [BridgePosition.south]: BridgePosition.north,
        [BridgePosition.west]: BridgePosition.east,
    }[bridgePosition];
};

const getPartialInitialSeatData = (
    attributes: Partial<SeatData>,
    seatPosition: keyof TableState['seatData'],
): Partial<SeatData> => {
    return (Object.keys(attributes) as (keyof SeatData)[]).reduce((partialInitialSeatData, currentValue) => {
        return {
            ...partialInitialSeatData,
            [currentValue]: initialTableState.seatData[seatPosition][currentValue],
        };
    }, {} as Partial<SeatData>);
};

export const setUniqueSeatDataAttribute = (
    seatData: TableState['seatData'],
    attributes: Partial<SeatData>,
    seat?: SeatPosition,
): TableState['seatData'] => {
    return {
        [SeatPosition.top]: {
            ...seatData[SeatPosition.top],
            ...getPartialInitialSeatData(attributes, SeatPosition.top),
        },
        [SeatPosition.right]: {
            ...seatData[SeatPosition.right],
            ...getPartialInitialSeatData(attributes, SeatPosition.right),
        },
        [SeatPosition.bottom]: {
            ...seatData[SeatPosition.bottom],
            ...getPartialInitialSeatData(attributes, SeatPosition.bottom),
        },
        [SeatPosition.left]: {
            ...seatData[SeatPosition.left],
            ...getPartialInitialSeatData(attributes, SeatPosition.left),
        },
        ...(seat
            ? {
                  [seat]: {
                      ...seatData[seat],
                      ...attributes,
                  },
              }
            : {}),
    };
};

export const getCssVarsForSuitColorSet = (suitColorSet: number): CSSProperties => {
    return {
        '--card-color-diamonds': possibleSuitColorSets[suitColorSet].diamonds,
        '--card-color-hearts': possibleSuitColorSets[suitColorSet].hearts,
        '--card-color-spades': possibleSuitColorSets[suitColorSet].spades,
        '--card-color-clubs': possibleSuitColorSets[suitColorSet].clubs,
    } as React.CSSProperties;
};

export const getClaimNotificationConfig = (bridgePosition: BridgePosition, claim: number) => {
    const id = 'claimNotification';
    return {
        id,
        noClickOutside: true,
        header: (
            <Translate
                contentKey="modal.claim.notification.header"
                interpolate={{
                    bridgePosition: `${bridgePosition[0].toUpperCase() + bridgePosition.substr(1).toLowerCase()}`,
                    claim: `${claim}`,
                }}
            />
        ),
        body: [
            <div key="body">
                <Translate
                    contentKey="modal.claim.notification.body"
                    interpolate={{ bridgePosition, claim: `${claim}` }}
                />
            </div>,
        ],
        cancelButtonLabel: <Translate contentKey="modal.reject" />,
        onCancel: () => store.dispatch(output_setApproveClaim('reject')),
        buttons: [
            {
                label: <Translate contentKey="modal.approve" />,
                primary: true,
                onClick: () => {
                    store.dispatch(output_setApproveClaim('approve'));
                    store.dispatch(app_removeModal(id));
                },
            },
        ],
        className: id,
    };
};

export const getUndoConfirmPrompt = (undos: string, action : () => void) =>{
    const id = 'requestUndoModal';
    return {
        id,
        header: <Translate contentKey="modal.undo.confirm.header"  interpolate={{ undos }} />,
        body: [
            <div key="body">
                <Translate contentKey="modal.undo.confirm.body"/>
            </div>,
        ],
        cancelButtonLabel: <Translate contentKey="modal.no" />,
        buttons: [
        {
            label: <Translate contentKey="modal.yes" />,
            primary: true,
            onClick: () => {
                action();
                store.dispatch(app_removeModal(id));
                store.dispatch(app_addModal({
                    id: 'awaitApproval',
                    header: <Translate contentKey="modal.undo.awaitApproval.header"/>,
                    body: [
                        <div key="body">
                            <Translate contentKey="modal.undo.awaitApproval.body"/>
                        </div>,
                    ],
                    isBlockUI: true
                }));
            }
        }
    ],
        className: 'requestUndoModal'
    }
}

export const getUndoNotificationConfig = (bridgePosition: BridgePosition) => {
    const id = 'undoNotification';
    return {
        id,
        header: <Translate contentKey="modal.undo.notification.header" interpolate={{ bridgePosition }} />,
        body: [
            <div key="body">
                <Translate contentKey="modal.undo.notification.body" interpolate={{ bridgePosition }} />
            </div>,
        ],
        cancelButtonLabel: <Translate contentKey="modal.reject" />,
        onCancel: () => store.dispatch(output_setApproveUndo('reject')),
        buttons: [
            {
                label: <Translate contentKey="modal.approve" />,
                primary: true,
                onClick: () => {
                    store.dispatch(output_setApproveUndo('approve'));
                    store.dispatch(app_removeModal(id));
                },
            },
        ],
        className: id,
    };
};

export const simplePopUp =  (header: string, body: string) =>{
    const id = 'simplePopUp';
    store.dispatch(app_clearModals());
    store.dispatch(app_addModal({
        id,
        header: header,
        body: [
            <div key="body"  dangerouslySetInnerHTML={{ __html: insertSuits(body) }} />
        ],
        cancelButtonLabel: <Translate contentKey="modal.ok" />
    }));
}

export const getConnectionLostConfig = (actions: { cancel: () => void; retry: () => void }) => {
    const id = 'connectionLost';
    return {
        id,
        noClickOutside: true,
        noHeaderClose: true,
        header: <Translate contentKey="modal.connectionLost.header" />,
        body: [
            <div key="body">
                <Translate contentKey="modal.connectionLost.body" />
            </div>,
        ],
        cancelButtonLabel: <Translate contentKey="modal.connectionLost.cancel" />,
        onCancel: () => actions.cancel(),
        buttons: [
            {
                label: <Translate contentKey="modal.connectionLost.retry" />,
                primary: true,
                onClick: () => {
                    actions.retry();
                    store.dispatch(app_removeModal(id));
                },
            },
        ],
        className: id,
    };
};

export const getInvalidLoginConfig = (actions: { cancel: () => void; retry: () => void }) => {
    const id = 'connectionLost';
    return {
        id,
        noClickOutside: true,
        noHeaderClose: true,
        header: 'Invalid classroom link!',
        body: [<div key="body">Contact your bridge teacher.</div>],
        cancelButtonLabel: <Translate contentKey="modal.connectionLost.cancel" />,
        onCancel: () => actions.cancel(),
        buttons: [
            {
                label: <Translate contentKey="modal.connectionLost.retry" />,
                primary: true,
                onClick: () => {
                    actions.retry();
                    store.dispatch(app_removeModal(id));
                },
            },
        ],
        className: id,
    };
};

export const getSocketErrorConfig = (actions: { cancel: () => void; retry: () => void }) => {
    const id = 'connectionLost';
    return {
        id,
        noClickOutside: true,
        noHeaderClose: true,
        header: 'Network Error!',
        body: [<div key="body">Try to Reconnect, if the problem persists, contact your teacher.</div>],
        cancelButtonLabel: <Translate contentKey="modal.connectionLost.cancel" />,
        onCancel: () => actions.cancel(),
        buttons: [
            {
                label: <Translate contentKey="modal.connectionLost.retry" />,
                primary: true,
                onClick: () => {
                    actions.retry();
                    store.dispatch(app_removeModal(id));
                },
            },
        ],
        className: id,
    };
};

export const getAlert = (body: Modal['body']) => {
    return {
        body,
        cancelButtonLabel: <Translate contentKey="modal.ok" />,
    };
};

export const getSplash = (body: Modal['body'], header: Modal['header'], blocking: boolean) => {
    return {
        id: 'NonBlockingSpalash',
        body,
        header,
        isBlockUI: blocking,
    };
};

export const getLeftOfBridgePosition = (
    bridgePosition: BridgePosition,
    seatData: TableState['seatData'],
): BridgePosition | undefined => {
    const currentSeatPosition = bridgePositionToSeatPosition(seatData, bridgePosition);
    if (!currentSeatPosition) {
        return;
    }
    const leftSeatPosition = {
        [SeatPosition.top]: SeatPosition.right,
        [SeatPosition.right]: SeatPosition.bottom,
        [SeatPosition.bottom]: SeatPosition.left,
        [SeatPosition.left]: SeatPosition.top,
    }[currentSeatPosition];

    return getBridgePositionFromSeatPosition(leftSeatPosition, seatData);
};

export const getBridgePositionFromSeatPosition = (
    seatPosition: SeatPosition,
    seatData: TableState['seatData'],
): BridgePosition | undefined => {
    return seatData[seatPosition].bridgePosition;
};

export const isVulnerable = (bridgePosition: BridgePosition, vulnerable: TableState['vulnerable']): boolean => {
    if (!vulnerable) {
        return false;
    }
    const bridgePositions = {
        [Vulnerable.none]: [],
        [Vulnerable.ew]: [BridgePosition.east, BridgePosition.west],
        [Vulnerable.ns]: [BridgePosition.north, BridgePosition.south],
        [Vulnerable.both]: [BridgePosition.east, BridgePosition.west, BridgePosition.north, BridgePosition.south],
    };
    const vulnerableBridgePositions: BridgePosition[] = bridgePositions[vulnerable];
    return vulnerableBridgePositions.indexOf(bridgePosition) !== -1;
};

const getKeyValue = (key: string, value: string | number): ReactElement => {
    return (
        <div>
            <span className="key">
                <Translate contentKey={`table.metaData.${key}.label`} />:{' '}
            </span>
            <span
                className="value"
                dangerouslySetInnerHTML={{
                    __html: insertSuits(
                        `${value === 'default' ? <Translate contentKey={`table.metaData.${key}.default`} /> : value}`,
                    ),
                }}
            />
        </div>
    );
};

type ListObject = Record<string, any[] | object>;

const arrayToList = (array: any[], key?: string): ReactElement => {
    return (
        <div className={`array ${key}`}>
            {array
                .filter((item) => item)
                .map((item, index) => {
                    if (!item) {
                        return undefined;
                    } else if (Array.isArray(item)) {
                        return arrayToList(item);
                    } else if (typeof item === 'object') {
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                        return objectToList(item as ListObject);
                    } else {
                        return (
                            <div key={`${key}-${index}`} className="item">
                                {item}
                            </div>
                        );
                    }
                })}
        </div>
    );
};

const objectToList = (object: ListObject): any[] => {
    const keys: string[] = Object.keys(object);
    return keys
        .filter((key) => object[key])
        .map((key) => {
            const value = object[key];
            if (Array.isArray(value)) {
                return arrayToList(value, key);
            } else if (typeof value === 'object') {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                return objectToList(value as ListObject);
            } else {
                return getKeyValue(key, value as string);
            }
        });
};

const cardIdToShort = (cardId: Card['id']) => {
    const array = cardId.split('.');
    return (
        <div className={`card ${array[0]}`}>
            <Translate contentKey={`rank.short.${array[1]}`} />
        </div>
    );
};

export const getMetaDataToList = (metaData: TableState['sharkMetaData']): any[] => {
    if (!metaData) {
        return [];
    }
    const { eventName, hostName, round, resultUrl, tableResults, welcomeMessage, comment } = metaData;
    const tableResultCols: (keyof TableResult)[] = [
        'boardNumber',
        'contract',
        'declarer',
        'lead',
        'result',
        'nsPoints',
        'ewPoints',
    ];
    return [
        ...(welcomeMessage
            ? [
                  <div
                      key="welcomeMessage"
                      className="welcomeMessage"
                      dangerouslySetInnerHTML={{ __html: welcomeMessage }}
                  />,
              ]
            : []),
        ...(comment ? [getKeyValue('comment', comment)] : []),
        ...(eventName ? [getKeyValue('eventName', eventName)] : []),
        ...(hostName ? [getKeyValue('hostName', hostName)] : []),
        ...(round ? [getKeyValue('round', round)] : []),
        // ...(resultUrl ? [<a href={resultUrl} target="_blank">{t(`table.metaData.resultUrl.label`)}</a>] : []),
        ...(tableResults?.length
            ? [
                  <div key="tableResults" className="tableResults">
                      {/*<div className="label">{t(`table.metaData.tableResults.label`)}</div>*/}
                      <div className="label">
                          <Translate contentKey={`table.metaData.tableResults.label`} />
                      </div>
                      <table>
                          <thead>
                              <tr>
                                  {tableResultCols.map((col) => (
                                      <th key={col}>
                                          <Translate contentKey={`table.metaData.${col}.label`} />
                                      </th>
                                  ))}
                              </tr>
                          </thead>
                          <tbody>
                              {tableResults.map((tableResult) => (
                                  <tr
                                      key={`${tableResult.boardNumber}-${tableResult.contract}-${tableResult.declarer}`}
                                  >
                                      {tableResultCols.map((col) => (
                                          <td key={col}>
                                              {col === 'lead' && tableResult.lead
                                                  ? cardIdToShort(tableResult.lead)
                                                  : tableResult[col]}
                                          </td>
                                      ))}
                                  </tr>
                              ))}
                          </tbody>
                      </table>
                  </div>,
              ]
            : []),
    ];
};

export const getGameResultModalConfig = (
    gameResults: TableState['sharkGameResults'] | TableState['sharkGameResultsV2'],
    isV2?: boolean,
): Modal => {
    if (!gameResults) {
        return {};
    }
    return {
        id: 'gameResultsModal',
        cancelButtonLabel: <Translate contentKey="table.metaData.ok" />,
        header: (
            <span>
                <span className="base">
                    {gameResults.EventName ? gameResults.EventName : <Translate contentKey="gameResults.header" />}
                </span>
                <span className="base">{gameResults.HostName ? '/ ' + gameResults.HostName + ' /' : ''}</span>
                <span className="timeStamp">
                    {gameResults?.Movement} - {gameResults?.Scoring} &nbsp;&nbsp;&nbsp;
                </span>
                <span className="timeStamp">
                    <Translate contentKey={'gameResults.updatedOn'} />:{' '}
                    {moment
                        .utc(moment.unix(gameResults.TimeStamp - 120))
                        .local()
                        .format('dddd, MMMM Do YYYY HH:mm (UTC Z)')}
                </span>
            </span>
        ),
        body: [isV2 ? <GameResultsV2 /> : <GameResults />],
    };
};

export const getGameResultBlockingModalConfig = (
    gameResults: TableState['sharkGameResults'] | TableState['sharkGameResultsV2'],
    isV2: boolean,
): Modal => {
    if (!gameResults) {
        return {};
    }
    return {
        ...getGameResultModalConfig(gameResults, isV2),
        isBlockUI: true,
    };
};

export const insertSuits = (message: string): string => {
    try {
        let output = message;
        output = output.replace(/[/|\\]S/gim, '<span class="spade">&#9824;</span>');
        output = output.replace(/[/|\\]H/gim, '<span class="heart">&#9829;</span>');
        output = output.replace(/[/|\\]C/gim, '<span class="club">&#9827;</span>');
        output = output.replace(/[/|\\]D/gim, '<span class="diamond">&#9830;</span>');
        output = output.replace(/[/|\\]n/gim, '<br />');
        return output;
    } catch (exception) {
        return message;
    }
};
