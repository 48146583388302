import './footer-component.scss';
import { ButtonComponent } from '../button-component/button-component';
import { appActions } from '../../slices/appSlice';
import {classNames} from '../../utils/mixed';
import { environment } from '../../env';
import { outputActions } from '../../slices/outputSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import React from 'react';

export const FooterComponent: React.FC = () => {
    const {footerButtons, forceShowAllCards} = useAppSelector((state) => state.table);
    const dispatch = useAppDispatch();
    const {output_setButton} = outputActions;
    const {app_setShowSettings} = appActions;
    const {engineState} = useAppSelector((state) => state.app);
    return (
        <footer className={classNames('FooterComponent', engineState.service)}>
            {Object.values(footerButtons).map(button => (
                <div key={`${button?.key}`} className={classNames(`${button?.key}`)}>{button &&
                    <ButtonComponent button={button}/>}</div>
            ))}
            <div className="settings" onClick={() => dispatch(app_setShowSettings(true))}>
                <img src="./images/settings30_30.png"/>
            </div>
            {
                engineState.service === 'BetterBridgeClub' &&
                <div className="card-button" onClick={() => dispatch(output_setButton({id: 'showAll', value: true}))}>
                    {forceShowAllCards ? <img src="./card_set/cardback_blue.png"/> : <img src="./card_set/sk.png"/>}
                </div>
            }
        </footer>
    );
};