import {
    Bid,
    Card,
    ClickEventData,
    SharkGameResultsPairV2,
    SharkGameResultsTravelerV2,
    SharkGameResultsV2,
} from '../app/types';
import { Button } from './tableSlice';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type OutputState = {
    approveClaim: 'approve' | 'reject' | undefined;
    approveUndo: 'approve' | 'reject' | undefined;
    button: Pick<Button, 'id' | 'value'> | undefined;
    bid: Bid | undefined;
    card: Card | undefined;
    claim: number | undefined;
    clickEventData: ClickEventData | undefined;
    loadBoardReview:
        | {
              bn: SharkGameResultsTravelerV2['bn'];
              pair: SharkGameResultsPairV2['uuid'];
              game: SharkGameResultsV2['UUID'];
              comparisonUuid?: SharkGameResultsPairV2['uuid'];
          }
        | undefined;
};

export const initialState: OutputState = {
    approveClaim: undefined,
    approveUndo: undefined,
    button: undefined,
    bid: undefined,
    card: undefined,
    claim: undefined,
    clickEventData: undefined,
    loadBoardReview: undefined,
};

export const outputSlice = createSlice({
    name: 'output',
    initialState,
    reducers: {
        output_loadBoardReview: (state, { payload }: PayloadAction<OutputState['loadBoardReview']>) => {
            state.loadBoardReview = payload;
        },
        output_setApproveClaim: (state, { payload }: PayloadAction<OutputState['approveClaim']>) => {
            state.approveClaim = payload;
        },
        output_setApproveUndo: (state, { payload }: PayloadAction<OutputState['approveUndo']>) => {
            state.approveUndo = payload;
        },
        output_setButton: (state, { payload }: PayloadAction<OutputState['button']>) => {
            state.button = payload;
        },
        output_setBid: (state, { payload }: PayloadAction<Bid>) => {
            state.bid = payload;
        },
        output_setCard: (state, { payload }: PayloadAction<Card>) => {
            state.card = payload;
        },
        output_setMakeClaim: (state, { payload }: PayloadAction<number>) => {
            state.claim = payload;
        },
        output_setWalkThruTarget: (state, { payload }: PayloadAction<OutputState['clickEventData']>) => {
            state.clickEventData = payload;
        },
        // resets
        output_reset: () => initialState,
    },
});

export const outputActions = outputSlice.actions;

// TODO: add some selectors
// export const selectHighlighted = (state: RootState) => state.card.highlighted;
// export const selectRaised = (state: RootState) => state.card.raised;
// export const selectRank = (state: RootState) => state.card.rank;
// export const selectSuit = (state: RootState) => state.card.suit;
// export const selectSuitSymbol = (state: RootState) => state.card.suitSymbol;
// export const selectVisible = (state: RootState) => state.card.visible;

export default outputSlice.reducer;
