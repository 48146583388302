import { GameEngine } from '../game-engine-helper';
import { SFSObject } from 'sfs2x-api';
import { SFSVAR } from '../sfsVar';

export function MID_CLAIM_NOTIFICATION(cmdObj: SFSObject, sender: GameEngine) {
    //North visible if handVisibility & 2 != 0, East & 4, South & 8, West &16
    sender.setHandsFromState(cmdObj, false);
    const tricks: number = cmdObj.getInt(SFSVAR.SFSGAME_MID_LOAD);
    const seat: number = cmdObj.getInt(SFSVAR.SFSGAME_SEAT_ID);
    sender.clientResponseClaim('approve');
}

export function MID_CLAIM_NOTIFICATION_SB(cmdObj: SFSObject, sender: GameEngine) {
    // this.setHandsFromState(cmdObj);
    // const tricks: number = cmdObj.getInt(SFSVAR.SFSGAME_MID_LOAD);
    // const seat: number = cmdObj.getInt(SFSVAR.SFSGAME_SEAT_ID);
    // this.setHandVisibility(31);
    // this.showModal(getClaimNotificationConfig(convertIntToBridgePosition(seat), tricks));
}
