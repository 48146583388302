import { GameEngine } from '../game-engine-helper';
import { SFSObject } from 'sfs2x-api';
import { SFSVAR } from '../sfsVar';

export function MID_SHOW_WELCOME(cmdObj: SFSObject, sender: GameEngine) {
    if (sender.state.lobbyRoom?.containsVariable(SFSVAR.SFSGAME_ROOM_WELCOME_MESSAGE)) {
        sender.setSimpleMessage(
            'center',
            sender.state.lobbyRoom?.getVariable(SFSVAR.SFSGAME_ROOM_WELCOME_MESSAGE).value as string,
            'OK',
        );
    }
}

export function MID_SHOW_WELCOME_SB(cmdObj: SFSObject, sender: GameEngine) {
    // //process pop-ups from server side.
    // //console.log('Welcome message', this.props.game.metaData.welcomeMessage);
    // //console.log('Game phase', this.props.game.gamePhase);
    // if (this.props.game.metaData.welcomeMessage) {
    //     const isMiniBridge: boolean =
    //         this.sfs?.lastJoinedRoom.containsVariable(SFSVAR.SFSGAME_MINIBRIDGE) &&
    //         this.sfs?.lastJoinedRoom.getVariable(SFSVAR.SFSGAME_MINIBRIDGE).value;
    //     const modalMessage: IModal = getSplash(
    //         [<div>Choose a contract from the bidding box on the bottom right.</div>],
    //          'This is mini bridge!',
    //          true
    //     );
    //     this.props.addModal({
    //         noHeaderClose: true,
    //         header: this.props.game.metaData.eventName,
    //         body: [
    //             <div>
    //                 <p dangerouslySetInnerHTML={{ __html: insertSuits(this.props.game.metaData.welcomeMessage) }} />
    //     </div>
    // ],
    //     cancelButtonLabel: <Translate contentKey="modal.ok" />,
    //         onCancel: () => (isMiniBridge && this.props.game.gamePhase === GamePhase.BID ? this.showModal(modalMessage) : () => {})
    // });
    // }
}
