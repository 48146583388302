import './trick-component.scss';
import { CardComponent } from '../card-component/card-component';
import { CardId, Trick } from '../../app/types';
import { SeatPosFor } from '../../app-interfaces/game-engine-helper';
import { cardAnimationSpeed } from '../../app/defaults';
import { classNames, cssVars } from '../../utils/mixed';
import { useAppSelector } from '../../app/hooks';
import React, { CSSProperties } from 'react';

type TrickComponentProps = {
    trick: Trick;
    show: boolean;
};

export const TrickComponent: React.FC<TrickComponentProps> = ({ trick: { cardIds, winner }, show }) => {
    return (
        <section className={classNames('TrickComponent', winner && 'claimed', show && 'show')}>
            {cardIds.map((cardId) => (
                <TrickLane cardId={cardId} key={cardId} />
            ))}
        </section>
    );
};

const TrickLane: React.FC<{ cardId: CardId }> = ({ cardId }) => {
    const cards = useAppSelector((state) => state.cards);
    const {
        settings: { speedCardAnimations },
    } = useAppSelector((state) => state.app);
    const card = cards[cardId];
    const style: CSSProperties = cssVars({
        '--animationDuration': `${cardAnimationSpeed[speedCardAnimations]}ms`,
    });
    const { table } = useAppSelector((state) => state);
    const { seatData } = useAppSelector((state) => state.table);
    return (
        <section className={classNames('TrickLane', SeatPosFor(card.bridgePosition, table))} style={style}>
            <CardComponent card={card} hand={{ isInteractive: false, isVisible: true }} />
        </section>
    );
};
