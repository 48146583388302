import { BridgePosition, SeatPosition } from '../../app/types';
import { GameEngine, SeatPosFor, convertIntToBridgePosition } from '../game-engine-helper';
import { SFSObject } from 'sfs2x-api';
import { SFSVAR } from '../sfsVar';
import { bridgePos } from '../../app/defaults';
import { claimButton, undoButton } from '../buttonsState';

export function MID_TIME_TO_PLAY(cmdObj: SFSObject, sender: GameEngine) {
    if (!cmdObj.containsKey('closetrick') || !cmdObj.getBool('closetrick')) {
        sender.props.table_shouldCloseTrick(false);
    }
    const currentPlayer: number = cmdObj.getInt(SFSVAR.SFSGAME_CURENT_PLAYER);
    const player: BridgePosition = convertIntToBridgePosition(currentPlayer);
    sender.setState({
        currentPlayer: bridgePos(currentPlayer),
    });

    Object.values(SeatPosition).forEach((value, index) => {
        sender.props.table_setSeatData({
            seatPosition: value,
            background: undefined,
        });
    });

    sender.props.table_setSeatData({
        seatPosition: SeatPosFor(bridgePos(currentPlayer), sender.props.table),
        isInteractive: true,
        isVisible: true,
        background: '#fce303',
    });
    sender.setCurrentPlayer(player);

    if (sender.sfs && sender.sfs.mySelf.getPlayerId(sender.sfs.lastJoinedRoom) > 0) {
        if (sender.shouldShowClaim()) {
            sender.showButtons([claimButton]);
        }
        if (sender.shouldShowUndo()) {
            sender.showButtons([undoButton]);
        }
    }

    // console.log('process_MID_TIME_TO_PLAY', cmdObj.getDump());
}
