import { GameEngine } from './game-engine-helper';
import {
    GameEngineInterface,
    ProcessorFunction,
    mapDispatchToProps,
    mapStateToProps,
} from './game-engine-interface.class';
import { SFSArray, SFSObject, SFSRoom } from 'sfs2x-api';
import { SFSConnectionStatus } from '../slices/appSlice';
import { SFSVAR, getSFSFalse, getSFSTrue } from './sfsVar';
import { TableInfo } from '../slices/tableSlice';
import { connect } from 'react-redux';
import { environment } from '../env';
import { tableInfoFromSFSObject } from './handState';
import process from './process-mid/collect';

export class AudreyGameEngineInterface extends GameEngineInterface {
    private mid_processor: Record<number, ProcessorFunction> = {
        [SFSVAR.netMID.MID_NEW_DEAL]: process.MID_NEW_DEAL, // 0
        [SFSVAR.netMID.MID_CURRENT_DEAL]: process.MID_CURRENT_DEAL, // 1
        [SFSVAR.netMID.MID_CHANGE_HAND_VISIBILITY]: process.MID_CHANGE_HAND_VISIBILITY, // 1
        [SFSVAR.netMID.MID_TIME_BID]: process.MID_TIME_TO_BID, // 5
        [SFSVAR.netMID.MID_MADE_BID]: process.MID_MADE_BID, // 6
        [SFSVAR.netMID.MID_TIME_PLAY]: process.MID_TIME_TO_PLAY, // 8
        [SFSVAR.netMID.MID_MADE_PLAY]: process.MID_MADE_PLAY, // 9
        [SFSVAR.netMID.MID_TIME_CLOSE_TRICK]: process.MID_TIME_CLOSE_TRICK, // 10
        [SFSVAR.netMID.MID_TIME_SCORE]: process.MID_TIME_TO_SCORE, // 11
        [SFSVAR.netMID.MID_CLAIM_RESPONSE]: process.MID_CLAIM_RESPONSE, //15
        [SFSVAR.netMID.MID_CLAIM_NOTIFICATION]: process.MID_CLAIM_NOTIFICATION, //16
        [SFSVAR.netMID.MID_SHOW_WELCOME]: process.MID_SHOW_WELCOME, //45
    };
    protected processObject = (cmdObj: SFSObject) => {
        const mid: number = cmdObj.getInt(SFSVAR.EXTENSION_MID);
        //console.log('Process Audrey Object : ', SFSVAR.netMID[mid], this.mid_processor[mid]);
        const processor = this.mid_processor[mid];
        if (processor) {
            processor(cmdObj, this); // Call the method
        } else {
            console.log(`No processor found for MID ${mid}`);
        }
    };

    protected configureSFS() {
        console.log('Configuring Audrey Connection', this.props.app.urlParams);
        //get URL parameters, most likely will move to th
        this.config.host = this.props.app.urlParams?.['sfshost']
            ? this.props.app.urlParams?.['sfshost'] + '.emabridge.com'
            : 'sfs.emabridge.com';
        this.config.zone = this.props.app.urlParams?.['service'] ?? 'BetterBridgeClub';
        this.config.port = 8443;
        return;
    }

    protected onLoginError = ({ errorMessage, errorCode }: { errorMessage: string; errorCode: number }) => {
        console.log('Better Bridge onLoginError : ', errorMessage, errorCode);
        this.props.app_setEngineState({
            sfsConnectionStatus: SFSConnectionStatus.disconnected,
        });
    };

    protected onConnectionLost = ({ reason }: { reason: string }) => {
        console.log('onConnectionLost', reason, this.props.app.engineState);
        const { app } = this.props;
        this.props.app_setEngineState({
            sfsConnectionStatus: SFSConnectionStatus.disconnected,
        });
        parent.postMessage('reloaddash', '*');
        if (reason !== 'manual') {
            //Show modal mesage to reconnect
        } else {
            //Close this iFrame ( can iFrame close it self? )
        }
    };

    public setTableInfo(cmdObj: SFSObject) {
        //console.log("setTableInfo 9 audrey)", cmdObj.getDump());
        this.props.table_setTableSymbol(false);
        const state: SFSObject = cmdObj.getSFSObject('tablestate');
        const infoObj: SFSObject | undefined = state.containsKey('tbinfo') ? state.getSFSObject('tbinfo') : undefined;
        if (infoObj !== undefined) {
            const leftinfo: TableInfo[] = [];
            const rightinfo: TableInfo[] = [];
            leftinfo.push(tableInfoFromSFSObject(infoObj, 'dealer'));
            leftinfo.push(tableInfoFromSFSObject(infoObj, 'vuln'));
            leftinfo.push(tableInfoFromSFSObject(infoObj, 'deal'));
            leftinfo.push(infoObj.containsKey('dealInfo') ? infoObj.getUtfString('dealInfo') : '');

            rightinfo.push(tableInfoFromSFSObject(infoObj, 'cont'));
            rightinfo.push(tableInfoFromSFSObject(infoObj, 'decl'));
            rightinfo.push(tableInfoFromSFSObject(infoObj, 'obj'));

            this.props.table_setTableInfo({ left: leftinfo, right: rightinfo });
            return;
        }
        this.props.table_setTableInfo({ left: undefined, right: undefined });
    }

    public setContractFromSFSObject(cmdObj: SFSObject) {
        const gameState: SFSObject | undefined = cmdObj.containsKey('contract') ? cmdObj : undefined;
        if (this.state.isMiniBridge || getSFSFalse(gameState, SFSVAR.SFSGAME_NOAUCTION)) {
            this.props.table_setShowAuctionLine(false);
        } else {
            this.props.table_setShowAuctionLine(environment === 'audrey');
        }
        return;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AudreyGameEngineInterface);
